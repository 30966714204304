import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { ApiServiceService } from '../services/api-service.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit {
  isEditable = true;
  isFirstDisabled = false;
  isSecondDisabled = false;
  isThirdDisabled = false;
  validation = false;
  userId = 1;
  constructor(
    private _formBuilder: FormBuilder,
    private http : HttpClient,
    private apiService: ApiServiceService
    ) {}
    checkValidation(data:any){
      console.log(data);
      if(data.event_name != "" && data.start_date != "" && data.end_date != "" && data.start_time != ""){
        this.isFirstDisabled = false;
      }else{
        this.isFirstDisabled = true;
      }
    }
    checkValidationSec(data:any){
      console.log('checkValidationSec',data);
      if(data.event_day_type != "" && data.event_charge != "" && data.start_location != "" && data.end_location != "" && data.event_type != ""){
        this.isSecondDisabled = false;
      }else{
        this.isSecondDisabled = true;
      }
    }
    checkValidationThird(data:any){
      console.log(data);
      if(data.image != ""){
        this.isThirdDisabled = false;
        this.validation = true;
      }else{
        this.isSecondDisabled = true;
      }
    }

  onSubmit(data:any){
    if(this.validation == true){
      data.created_by = this.userId;
    console.log("HELLLO DATA IS HERE",data);
    this.http.post(environment.apiUrl+'event/create', data).subscribe(datas => {
        console.log(datas);
    });
  }
  }
  ngOnInit() {
    var api = this.apiService.findAll('story/my/1');
    api.toPromise().then(res => { 
        console.log('SSSSSSSS',res);
    },msg => { 
        console.log('SSSSSSSS',msg);
    });
      this.isFirstDisabled = true;
      this.isSecondDisabled = true;
      this.isThirdDisabled = true;
  }
}