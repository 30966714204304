import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ThemePalette} from '@angular/material/core';

export interface PeriodicElement {
  username: any;
  firstname: string;
  lastname: any;
  emailname: any;
  status: any;
  action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { username: 'saddam_kaka', firstname: 'Saddam Hussian', lastname: 'Solankey', emailname: 'saddamsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohammad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'SalimKhan_774', firstname: 'Mohammed Salim', lastname: 'Deshwali', emailname: 'sks.deshwali@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
  { username: 'aarif_solankey12', firstname: 'Mohmmad Aarif', lastname: 'Solankey', emailname: 'Aarifsolankey@gmail.com',status: 'Active',  action: ''},
];

@Component({
  selector: 'app-admin-userlist',
  templateUrl: './admin-userlist.component.html',
  styleUrls: ['./admin-userlist.component.css']
})


export class AdminUserlistComponent implements AfterViewInit {
  color: ThemePalette = 'accent';
  
  checked = false;
  disabled = false;

  displayedColumns: string[] = ['select', 'username', 'firstname', 'lastname', 'emailname', 'status', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.username + 1}`;
  }
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnInit(): void {
  }

}
