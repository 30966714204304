<div class="container">
    <div fxLayout.xs="column" fxLayoutGap="%"fxLayoutAlign.xs="center">
       <mat-card class="example-card col-sm-12 col-md-12 col-12 mx-auto">
           <img class=" mx-auto imgc " mat-card-image src="../../assets/img/exploreologo.png" >
           <div class=" fsp ">
            <p class=""> Sign up to see the photos and videos<br> from your Friend</p>
          </div>
           <div class="container">
          
            <div class="  col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div class="mx-auto mt-2" >
                <img class="fgi" src=" ../../assets/img/Group 102.png" >
                <img  class="fgi" src=" ../../assets/img/Group 104.png" >
              </div>
                
            </div>
           
            <div class="row mt-3 ">
              <div class="col "><hr class="sss"></div>
              <div class="or mt-2">OR</div>
              <div class="col "><hr class="sss"></div>
          </div>
          <form [formGroup]="registerForm" #user="ngForm" (ngSubmit)="onSubmit(user.value)" class="font">
              <div class="form-group">
                <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                  <input formControlName="username" class="form-control rounded-0 hi" matInput type="text"  ngModel required name="username"  placeholder="username"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                 
                </div>
              </div>
              <!-- <p style="color: red;">{{error}}</p> -->
              <div *ngIf="submitted && f.username.errors" class="invalid-username">
                <div style="color: red;" *ngIf="f.username.errors.required">username is required</div>
              </div>
              
              <div class="form-group">
                <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                  <input formControlName="email" class="form-control rounded-0 hi" matInput type="text" name="email" ngModel required placeholder="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  
                </div>
              </div>
              <p style="color: red;">{{error}}</p>
              <div *ngIf="submitted && f.email.errors" class="invalid-email">
                <div style="color: red;" *ngIf="f.email.errors.required">email is required</div>
              </div>
              
              <div class="form-group">
                <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                  <input formControlName="phone_no" class="form-control rounded-0 hi" matInput type="text"  name="phone_no"  ngModel required  placeholder="phone_no" [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" />
                  
                </div>
              </div>
              <div *ngIf="submitted && f.phone_no.errors" class="invalid-phone_no">
                <div style="color: red;" *ngIf="f.phone_no.errors.required">phone_no is required</div>
              </div>
              
              <div class="form-group">
                <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                  <input formControlName="password" class="form-control rounded-0 hi" matInput type="password" name="password" ngModel required placeholder="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  
                </div>
              </div>
              <div *ngIf="submitted && f.password.errors" class="invalid-password">
                <div style="color: red;" *ngIf="f.password.errors.required">password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
              </div>

              <button class="rounded-0 bbc text-white mt-3"mat-raised-button > Sign up</button>
               
                 <!-- <div  class="row " fxLayout.xs="column"fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign.xs="                  nter" >
               <div class="mx-auto mt-2" >
                <img class="fgi" src=" ../../assets/img/facebook.png" >
                <img  class="fgi" src=" ../../assets/img/Google.png" >
              </div>
             </div> -->
             <div class=" fspt">

              
              <p> By signing up, you agree to our Terms,<br> Data Policy and Cookies Policy</p>
            </div>
             </form>
           </div>
         </mat-card>
       
    </div>
   </div>