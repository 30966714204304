import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { PopupComponent } from './popup.component';
import { PopupcmtComponent } from './popupcmt.component';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { StorypopupComponent } from '../story/storypopup.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  slidess = [
  {'image': '../../assets/img/glenn-hansen-FMi26r2xrCk-unsplash (1).jpg'},
  {'image': 'https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg'},
  {'image': 'https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg'}
];

  //items = {};
   items :any;
   pageOfItems: Array<any> | undefined;
  name = 'Angular';
  result:any;
  totalLength:any;
  page = 1;
  userId = 90;
  timelineId = 1;
  storiesData:any;
  eventId = 1;
  followingId = 1;
  postData:any;
  liked = false;
  saved = false;
  getData:any;
  show = false;
  message:any;
  imageSrc= ''
  responses:any;
  suggestion:any;
  selected = false;
  postelement:any;
  toggle=true;
  response:any;
  myLiked:any;
  mySaved:any;
  searchUser:any;
  searchValue = 0;
  // data: string;
  // error: string;
  datas:any;
  imageUrl = environment.imageUrl;
  dummyUserProfile = environment.dummyUserProfile;
  filterTerm: any;
  values = '';
  constructor(
    public dialog: MatDialog,
    private http : HttpClient,) {
      this.response={data:{story:{data:[]}}}
      //this.postelement={myLiked:['liked']}
      this.postelement={myLiked:{}}
      this.postelement={mySaved:{}}
    }
    
    onKey(event: any){
      console.log('asdasd',event.target.value);
        this.http.get(environment.apiUrl+'userSearch/'+event.target.value)
        .subscribe((datas:any) => {  
          this.searchUser = datas;   
          this.searchValue = event.target.value.length; 
          if(event.target.value.length <= 0){
            this.searchUser = {};
          }  
          console.log("fddfdfdfdf ", this.searchUser)
      })
    }
    
    
  openDialog() {
      const dialogRef = this.dialog.open(PopupComponent, {
        
        width: '30%',
        height: '300px',
        
        data: {},

        position: {
          
          top: '95px',
          right: '190px',
          
          
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogcmt() {
    const dialogReff = this.dialog.open(PopupcmtComponent,{

    width: '70%',
        data: {},
        position: {
        
        }
      });
    dialogReff.afterClosed().subscribe(result => {
      console.log(`Dialog result: `,result);
    });
  }
  storypopup() {
    const dialogReff = this.dialog.open(StorypopupComponent,{

    width: '29%',
        data: {},
        position: {
        
        }
      });
    dialogReff.afterClosed().subscribe(result => {
      console.log(`Dialog result: `,result);
    });
  }
  
  slides = [  
    {'image': '../../assets/img/motorbike-3548342.png'}, 
     {'image': '../../assets/img/motorbike-3548342.png'}];
     async fetchData() {
      this.message = "Fetching..";
      console.log("Home Data",this.page);
      await this.http.get(environment.apiUrl+'home/post/'+this.userId+'/'+this.page)
        .subscribe((datas:any) => {  
          this.response = datas; 
          // this.items = Array(datas.data.count).fill(0).map((x, i) => (this.response.data.data));
          // console.log('----saR-',this.items)
      });  
        // console.log("home get data",this.response)
      this.message = "Fetched";
    }

    onChangePage(pageOfItems: Array<any>) {
      // update current page of items
      
      console.log('---page-----',pageOfItems)
      this.pageOfItems = pageOfItems;
      
  }

    
    likeUnlike(timelineId:any,key:any){
      // console.log("hehehehe",timelineId)
      this.http.post(environment.apiUrl+'LikeUnlike/'+timelineId+'/'+this.userId,{})
      .subscribe((datas:any) => {
        this.liked = false;
        // console.log('------jahangirKhan--------',datas);

        if(datas.status == true){
          // console.log('---data----',this.response.data.post[key].myLiked);
          // this.postelement[key].myLiked = datas.data
          if(datas.msg == "post Unlike successfully"){
            this.response.data.post[key].myLiked = null;
             
          }else{
            this.response.data.post[key].myLiked = datas.data
          }

        }else{
          // this.postelement.myLiked = datas.data;
        }
      });
      }

      commentlike(commentId:any){
        var data = {
          comment_id : commentId,
          user_id : this.userId
        }
        // console.log("hehehehe",commentId)
        this.http.post(environment.apiUrl+'commentLike/post/',data)
        .subscribe(datas => {
          this.liked = false;
          if(datas == 'like  insert successfully'){
            this.postelement.myCommLike = {'asda':'asd'};
          }else{
            this.postelement.myCommLike = null;
          }
        // console.log("like after data post " ,this.postelement.myCommLike);  
        });
        }

      // commentlike(commentId:any,key:any){
      //   // console.log("hehehehe",timelineId)
      //   this.http.post(environment.apiUrl+'commentLike/post/'+commentId+'/'+this.userId,{})
      //   .subscribe((datas:any) => {
      //     this.liked = false;
      //     console.log('------jahangirKhan--------',datas);
  
      //     if(datas.status == true){
      //       console.log('---data----',this.response.data.post[key].myCommLike);
      //       // this.postelement[key].myCommLike = datas.data
      //       if(datas.msg == "comment Unlike successfully"){
      //         this.response.data.post[key].myCommLike = null;
               
      //       }else{
      //         this.response.data.post[key].myCommLike = datas.data
      //       }
  
      //     }else{
      //       // this.postelement.myLiked = datas.data;
      //     }
      //   });
      //   }

    onSubmit(data:any,timelineId:any){
      data.comment_by =  this.userId;
      data.timeline_id =  timelineId;
      this.http.post(environment.apiUrl+'comment/post/', data)
        .subscribe(datas => {  
           console.log("comment post done",datas)      
      });
    }


    savePost(postId:any,key:any){ 
        var data = {
        user_id: this.userId,
        post_id : postId
      }

      this.http.post(environment.apiUrl+'savedUnsaved/' + this.userId + '/'+postId,data)
      // .subscribe((datas:any) => {
      //   this.saved = true;
      //   if(datas.msg == 'save  insert successfully'){
      //     this.mySaved = 'saved';
      //   }else{
      //     this.postelement.mySaved = null;
      //   }
      // console.log("save after data post " ,this.postelement.mySaved);  
      // });
      .subscribe((datas:any) => {
        this.saved = false;
        // console.log('------jahangirKhan--------',datas);

        if(datas.status == true){
          // console.log('---data----',this.response.data.post[key].mySaved);
          if(datas.msg == "post UnSave successfully"){
            this.response.data.post[key].mySaved = null;
          }else{
            this.response.data.post[key].mySaved = datas.data
          }

        }else{
          // this.postelement.myLiked = datas.data;
        }
      });
    }
    

    async  eventallData(){
      this.message = "Fetching..";
      // console.log("Fetching..");
      this.responses = "";
      this.responses = await this.http
        .get<any>(environment.apiUrl+'event/findSuggestionAll/'+this.userId,{})
        .pipe()
        .toPromise();
        console.log("all event data successfully",this.responses)
      this.message = "Fetched";
    }



    
    error = '';
    async nearEvent() {
      this.message = "Fetching..";
      // console.log("Fetching..");
      this.responses = "";
      this.responses = await this.http
        .get<any>(environment.apiUrl+'event/findSuggestion/90',{})
        .pipe()
        .toPromise();
        // console.log("near event get data",this.responses)
      this.message = "Fetched";
    }

    suggestionallData(){
      this.http.post(environment.apiUrl+'user/findSuggestionAll/'+this.userId,{})
        .subscribe((data:any) => { 
          
          // console.log("Suggestion data successfully",data);
          this.suggestion = data.data;
      });
    }

    joinevenData(){
      var data = {
        user_id: this.userId,
        event_id : this.eventId
      }
      this.http.post(environment.apiUrl+'updateStatusJoined/1/1',data)
        .subscribe(datas => {  
          // console.log("post save successfully",datas)      
      });
    }
      

    followeventData(){
      var data = {
        follower_id : this.userId,
        following_id : this.followingId
      
      }
      this.http.post(environment.apiUrl+'follow/post/', data)
        .subscribe(datas => {  
          // console.log("request sent successfully",datas)      
      });
    }


  ngOnInit(): void {
    // console.log('---------Login user-------',localStorage.getItem('user'));
    this.userId;
    this.postData = this.fetchData();
    this.getData = this.nearEvent();

   // this.totalLength = this.result.length;

    this.http.post(environment.apiUrl+'user/findSuggestion/'+this.userId,{})
        .subscribe((data:any) => { 
          // console.log("---data----",data)   
          if(data.status){
            //Tst data and data true 
          }else{
            //data false or some error occurs
          }    
          //  console.log("Suggestion data her",data);
          this.suggestion = data.data;
      });
     
      // an example array of 150 items to be paged
      

  }


}