import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, delay } from "rxjs/operators";
import { MatVideoModule } from 'mat-video';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})

export class TestComponent implements OnInit {
 
  
  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }
  ngOnInit() {
		
	
    }

}
