    <div class="d-flex myStory story_status1">
        <div class="">
            <img type="button" class="story story_status" (click)="storypopup()" style="z-index: -1 !important;" src="../../assets/img/pexels-john-stelios.png" />
            <!-- <a href="" class="text-decoration-none">
                <mat-icon class="icon maticon" matCellDefix>add_circle</mat-icon>
            </a> -->
        </div>
        <div class="ml-3" *ngFor="let story of data.data.story.data">
            <img type="button" (click)="storypopup()" class="story story_status status_border rounded-circle"  src="{{imageUrl}}story_images/{{story.image}}"/>
            <h4>{{story.username}}</h4>
        </div>
    </div>
    