<!-- <div class="" style="width:60%;">
    <video class="video-fluid" autoplay loop>
        <source src="https://mdbootstrap.com/img/video/Lines.mp4" type="video/mp4" />
    </video>
</div> -->
<div>
   
    <video  class="video-fluid w-100" id="my_video_1"  loop >
        <source src="https://mdbootstrap.com/img/video/Lines.mp4"  type="video/mp4"/>
        
    </video>
    
</div>
<mat-icon (click)="playPause()" type="button" class="icon mt-1 border-0" matCellDefix style="font-size: 100px;color:red;position: absolute;top: 10px;text-align: center;">play_arrow</mat-icon>


   


