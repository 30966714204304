import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';




@Component({
  selector: 'app-home',
  templateUrl: './popup.component.html',
  styleUrls: ['./home.component.css']
})
export class PopupComponent implements OnInit {

  postData:any;
  message:any;
  notification: any;
  response:any;
  data: any;
  userId = 1;
  followingId = 1;
  imageUrl = environment.imageUrl;
  constructor(
    private http : HttpClient,) {
      this.response={data:[]}
      this.notification={data:{sender:{receiver:{timeline:[]}}}}
       
    }
   
    followbtnData(followingId:any){
      var data = {
        follower_id : this.userId,
        following_id : followingId
      
      }
      this.http.post(environment.apiUrl+'follow/post/', data)
        .subscribe(datas => {  
          console.log("request sent successfully",datas)      
      });
    }

  ngOnInit(): void {
    this.userId;

    this.http.post(environment.apiUrl+'notifications/'+this.userId,{})
        .subscribe((data:any) => { 
           console.log("notification data successfully",data);
           
          this.notification = data.data;
      });
      

  }

}
