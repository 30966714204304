<app-side-navs></app-side-navs>
<div class="bg"></div>
<div class="container mt-4">
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="7%" fxLayoutAlign="center">
        <div fxFlex="50%">
            <h2>Ladakh camping event by SoftVolt group.</h2>
        </div>
        <div fxFlex="50%">
            <button type="button" class="btn mobile_size_width rounded-0">
                <b>Join Event</b>
            </button>
        </div>
    </div>
    <div class="mt-4" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="7%" fxLayoutAlign="center">
        <div fxFlex="33.33%">
            <div class="d-flex">
                <div class="mt-1">
                    <img width="22px" height="22px" src="../../assets/img/clock.png">
                </div>
                <div>
                    <h4 class="ml-3">Thursday, 17th To 25th September</h4>
                    <p class="time_css ml-3">06:45 AM</p>
                </div>
            </div>
        </div>
        <div fxFlex="33.33%">
            <div class="d-flex">
                <div class="mt-1">
                    <img width="22px" height="24px" src="../../assets/img/Icon material-location-on.png">
                </div>
                <div>
                    <h4 class="ml-3">Sindhi camp, jaipur to Ladakh</h4>
                    <p class="time_css ml-3">Near bus stand</p>
                </div>
            </div>
        </div>
        <div fxFlex="33.33%">
            <div class="d-flex">
                <div class="mt-1">
                    <img width="22px" height="22px" src="../../assets/img/Icon material-account-circle.png">
                </div>
                <div>
                    <h4 class="ml-3">organized by softvolt group</h4>
                </div>
            </div>
        </div>
    </div>

    <!-- hadding 1  start -->

    <h2 class="mt-4">About Event</h2>
    <p class="text-muted">The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs. waltz. Brick quiz whangs jumpy veldt fox. Bright vixens jump; dozy fowl quack. Quick wafting zephyrs vex bold Jim. Quick zephyrs blow, vexing daft Jim. Sex-charged fop blew my junk TV quiz. How quickly daft jumping zebras vex. Two driven jocks help fax my big quiz. Quick, Baz, get my woven flax jodhpurs! "Now fax quiz Jack!" my brave ghost pled. Five quacking zephyrs jolt my wax bed. Flummoxed by job, kvetching W. zaps Iraq. Cozy sphinx waves quart jug of bad milk. A very bad quack might jinx zippy fowls. Few quips galvanized the mock jury box. Quick brown dogs jump over the lazy fox. The jay, pig, fox, zebra, and my wolves quack! Blowzy red vixens fight for a quick jump. Joaquin Phoenix was gazed by MTV for luck. A wizard’s job is to vex chumps.</p>


    <!-- hadding 1  end -->

    <!-- hadding 2  start -->

    <h2 class="mt-4">58 people are going</h2>
    <div class="d-flex ">
        <div>
            <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png">
        </div>
        <div class="ml-2">
            <img width="40px" height="40px" src="../../assets/img/NoPath - Copy (16).png">
        </div>
        <div class="ml-2">
            <img width="40px" height="40px" src="../../assets/img/NoPath - Copy (17).png">
        </div>
        <div class="ml-2">
            <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png">
        </div>
        <div class="ml-2">
            <img width="40px" height="40px" src="../../assets/img/NoPath - Copy (16).png">
        </div>
        <div class="ml-2">
            <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png">
        </div>
        <div class="">
            <p class="mt-2 ml-3">and 50</p>
        </div>
        <div class="mt-2">
            <a href="" class="ml-2">more</a>
        </div>
    </div>
    
    <!-- hadding 2 end -->

    <!-- hadding 3  start -->

    <h2>Comments</h2>
    <div class="d-flex">
        <div>
            <img width="36px" height="36px" src="../../assets/img/pexels-cottonbro-5192878.png">
        </div>
        <div>
            <h4 class="mt_css_h4 ml-2 d-flex" style="color: black;">mr_patric
            <p class="ml-2 text-muted">How many times did you try to slide</p>
            </h4>
        </div>
    </div>
    <div class="d-flex">
        <div>
            <img width="36px" height="36px" src="../../assets/img/pexels-cottonbro-5192878.png">
        </div>
        <div>
            <h4 class="mt_css_h4 ml-2 d-flex" style="color: black;">ux_maniac
            <p class="ml-2 text-muted"> The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog.</p>
            </h4>
        </div>
    </div>

    <!-- hadding 3 end -->
    
</div>
