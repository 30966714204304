<div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
    <div fxFlex="25%" class="ddddddddddddd">
        <div  class="p-0 pt-3">
            <div class="text-center">
              <img width="80px" height="80px" src="../../../assets/img/pexels-chloe-kala-1043473.png">
              <a href="" class="text-decoration-none">
                <h2 class="mt-2 text-white">SalimKhan_774</h2>
              </a>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/dashboard.png">
                <a href="admin/admin-landing" class="text-decoration-none">
                    <h4 class="ml-4 mt-2 text-white ">Dashboard</h4>
                </a>
              </div>
            </div>
            <div class="container-fluid bg_back">
                <div class="d-flex ml-3">
                  <img class="top_css_img icon_img_size" src="../../../assets/img/user.png">
                  <a href="admin/admin-userlist" class="text-decoration-none">
                      <h4 class="ml-4 mt-2 text-white ">User List</h4>
                  </a>
                </div>
              </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/paper-plane.png">
                <a href="" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white">Total Posts</h4>
                </a>
              </div>
            </div>
            
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/calendaricon.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">All Events</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/hashtag.png">
                <a href="admin/admin-hashtag" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Add Hashtag</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/file.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Reported Account</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/help.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">User Queries</h4>
              </a>
              </div>
            </div>
          </div>
    </div>
    <div fxFlex="75%" class="bbbbggg">
      <div class="d-flex">
        <h2 class="mt-4 vl ml-3">Reported issues</h2>
        
        <button class="download_bg rounded-0 ml-auto mt-4 mr-4" mat-button>Posts</button>
        <button class="download_bg2 rounded-0 border float-right mt-4 mr-4" mat-button>Comments</button>
        <button class="download_bg3 border rounded-0 float-right mt-4 mr-4" mat-button>Profile</button>
      </div>
        
      <table mat-table [dataSource]="dataSource" class="bbbbggg">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
      
        <!-- Position Column -->
        <ng-container matColumnDef="reported" >
          <th mat-header-cell *matHeaderCellDef class="text-dark">
              <b>reported</b>
          </th>
          <td mat-cell *matCellDef="let element" class="text_td_color"> {{element.reported}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="issue">
          <th mat-header-cell *matHeaderCellDef class="text-dark">
              <b>issue</b>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.issue}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="reportedBy">
          <th mat-header-cell *matHeaderCellDef class="text-dark">
              <b>reportedBy</b>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.reportedBy}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="discription" >
          <th mat-header-cell *matHeaderCellDef class="text-dark">
              <b>discription</b>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.discription}} </td>
        </ng-container>
        <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef class="text-dark">
                <b>datetime</b>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.datetime}} </td>
          </ng-container>
          
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-dark">
                <b>action</b>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon type="button" class="icon  border-0" matCellDefix>remove_red_eye</mat-icon>
                <mat-icon type="button" class="icon text-danger border-0" matCellDefix>delete</mat-icon>
            </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
<mat-paginator class="bbbbggg" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>