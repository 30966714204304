<div *ngFor="let story of response.data">
<div class="story_back_img">
    <div class="pt-1">
        <mat-progress-bar mode="determinate" [value]="pValu"></mat-progress-bar>
    </div>
    <div class="d-flex">
        <div class="d-flex mt-3 ml-3">
            <img width="35px" height="35px" src="../../assets/img/pexels-john-stelios.png">
            <a href="" class="text-decoration-none">
                <h2 class="font_css_h1 text-white ml-2">{{story.title}}</h2>
            </a>
        </div>
        <div class="ml-auto" style="margin-top: 21px;">
            <mat-icon class="text-white">more_vert</mat-icon>
            
        </div>
    </div>
    <div class="d-flex search_button_css" >
    <input type="text" class="form-control back_rgba_color text-white ml-3" placeholder="Send message"  >
        <div class="back_rgba_color1 rounded-circle text-center ml-2">
            <mat-icon class="text-white" style="margin-top: 9px;font-size: 20px;">send</mat-icon>
        </div>
    </div>
</div>
</div>
    <div mat-dialog-actions style="position: absolute;top: -10px;right: 15px;">
        <mat-icon mat-dialog-close aria-hidden="false" class="text-white" aria-label="Example home icon" style="font-size: 40px;">clear</mat-icon>
    </div>