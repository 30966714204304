import { Component, OnInit } from '@angular/core';
import { SocketioService } from '../socketio.service';
import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  title = 'socket-frontend';
  message:any;
  socket:any;

  constructor(private socketService: SocketioService) {}
  value = '';
  
  SendMessage(value: string) {
    this.value = value; 
    // console.log(this.value);
    this.socket = io(environment.SOCKET_ENDPOINT);
    var data = {
      msg:this.value,
      userId:1
    }
  var a = this.socket.emit('my message', data);
  // console.log("TEST",a);
  this.socket.on(environment.SOCKET_ENDPOINT, (data: string) => {
    // console.log(data);
  });
 }  
 ngOnInit(): void {
   this.setupSocketConnection();
  }
  setupSocketConnection() {
    // this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket = io( environment.SOCKET_ENDPOINT, {'transports': ['websocket', 'polling']});

    this.socket.on('message-broadcast', (data: string) => {
    if (data) {
     const element = document.createElement('li');
     element.innerHTML = data;
     element.style.background = 'white';
     element.style.padding =  '15px 30px';
     element.style.margin = '10px';
     console.log("setupSocketConnection",data);
     }
   });
 }
}
