import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../services/api-service.service';
import { environment } from './../../environments/environment';
import { StorypopupComponent } from '../story/storypopup.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['../home/home.component.css']
})
export class StoryComponent implements OnInit {
  constructor(private apiService:ApiServiceService,
    public dialog: MatDialog
    ) { }

  data:any;
  imageUrl = environment
  ngOnInit(): void {
    var api = this.apiService.findAll('home/post/1');
api.toPromise().then(res => {
this.data = res;
console.log('sasssasasasa',this.data)
},msg => {
console.log('SSSSSSSS',msg);
});
  }
  storypopup() {
    const dialogReff = this.dialog.open(StorypopupComponent,{

    width: '29%',
        data: {},
        position: {
        
        }
      });
    dialogReff.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
