<app-side-navs></app-side-navs>
<div class="container">
    <div class="profile_css" fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="7%" fxLayoutAlign="center">
        <div fxFlex.xs="35%" fxFlex.lg="20%" class="border_css">
            <app-setting-sidebar></app-setting-sidebar>
        </div>
        <div fxFlex.xs="65%" fxFlex.lg="80%" class="mt-4">
            <div class="text-right">
                <img width="70px" height="70px" class="image_css_mobile" src="../../assets/img/pexels-john-stelios.png" />
                
            </div>
            <div class="text-right mt-2 ">
                <a href="" class="text-decoration-none" style="color: #45A1DE;">salim Khan</a>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="left" class="mt-4">
                <div fxFlex="25%">
                    <h2 class="mt-2 text-right sks">Current Password</h2>
                </div>
                <mat-form-field fxFlex="70%" appearance="outline">
                    <input matInput placeholder="" />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="left" class="mt-4">
                <div fxFlex="25%">
                    <h2 class="mt-2 text-right sks">New password</h2>
                </div>
                <mat-form-field fxFlex="70%" appearance="outline">
                    <input matInput placeholder="" />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="left" class="mt-4">
                <div fxFlex="25%">
                    <h2 class="mt-2 text-right sks">Re-enter password</h2>
                </div>
                <mat-form-field fxFlex="70%" appearance="outline">
                    <input matInput placeholder="" />
                </mat-form-field>
            </div>
            <div class="text-center">
                <button class="btn_color_css" mat-raised-button>SUBMIT</button>
            </div>
        </div>
    </div>
</div>            