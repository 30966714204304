<div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
    <div fxFlex="25%" class="ddddddddddddd">
        <div  class="p-0 pt-3">
            <div class="text-center">
              <img width="80px" height="80px" src="../../../assets/img/pexels-chloe-kala-1043473.png">
              <a href="" class="text-decoration-none">
                <h2 class="mt-2 text-white">SalimKhan_774</h2>
              </a>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/dashboard.png">
                <a href="admin/admin-landing" class="text-decoration-none">
                    <h4 class="ml-4 mt-2 text-white ">Dashboard</h4>
                </a>
              </div>
            </div>
            <div class="container-fluid bg_back">
                <div class="d-flex ml-3">
                  <img class="top_css_img icon_img_size" src="../../../assets/img/user.png">
                  <a href="admin/admin-userlist" class="text-decoration-none">
                      <h4 class="ml-4 mt-2 text-white ">User List</h4>
                  </a>
                </div>
              </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/paper-plane.png">
                <a href="" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white">Total Posts</h4>
                </a>
              </div>
            </div>
            
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/calendaricon.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">All Events</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/hashtag.png">
                <a href="admin/admin-hashtag" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Add Hashtag</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/file.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Reported Account</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/help.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">User Queries</h4>
              </a>
              </div>
            </div>
          </div>
    </div>
    <div fxFlex="75%" class="bbbbggg">
        <div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
            <div fxFlex="60%" class="example">
                <div class="ml-3 d-flex">
                    <div>
                        <button class="mt-3" mat-button>BACK</button>
                    </div>
                    <div style="margin-top: 19px;">
                        <h2 class="color_h2_change">EXP-01</h2>
                    </div>
                </div>
                <div class="ml-4">
                    <h2>Tried to login multiple time but not able to login</h2>
                </div>
                <div class="ml-4">
                    <mat-card class="card_width_css rounded-0 border">
                        <h2 class="text-dark d-flex size_h2_css">Salim Khan
                            <p class="text-muted p_tag_css ml-2">Raised this issue</p>
                        </h2>
                        <h2 class="size_h2_css">Discription</h2>
                        <p class="text-muted">Hello Exploreo,</p>
                        <p class="text-muted">I am trying to login in my Exploreo since morning but I am not able to login.Requesting you to please check the issues and tried to resolved it ASAP.</p>
                    </mat-card>
                </div>
                <div class="ml-4 mt-3">
                    <h2 class="size_h2_css">Attachment</h2>
                    <img width="100px" height="70px" src="/assets/img/Icon awesome-image.png">
                    <img width="100px" height="70px" class="ml-3" src="/assets/img/Icon awesome-image.png">
                </div>
                <h2 class="size_h2_css ml-4 mt-3">Comments</h2>
                <div class="bg_color_back ml-4">
                    <div class="d-flex">
                        <img width="30px" height="30px" class="ml-3 mt-2" src="../../assets/img/pexels-chloe-kala-1043473.png">
                    <h2 class="h2_css ml-1 mt-2">Exploreosupport</h2>
                    <p class="text-muted p_css_p ml-2">July 22, 2020, 12.20 PM</p>
                    </div>
                    <p class="text-muted ml-3">Hi Salim,</p>
                    <p class="text-muted ml-3"> Thanks for informing the issues,<br> Our technical team is looking into it, we'll update you once the issue got resolved</p>
                </div>
                <div class="bg_color_back ml-4">
                    <div class="d-flex">
                        <img width="30px" height="30px" class="ml-3 mt-2" src="../../assets/img/pexels-chloe-kala-1043473.png">
                    <h2 class="h2_css ml-1 mt-2">Exploreosupport</h2>
                    <p class="text-muted p_css_p ml-2">July 22, 2020, 12.20 PM</p>
                    </div>
                    <p class="text-muted ml-3">Hi Salim,</p>
                    <p class="text-muted ml-3"> Thanks for informing the issues,<br> Our technical team is looking into it, we'll update you once the issue got resolved</p>
                </div>


            </div>
            <div fxFlex="40%" class="right_div_css">
                <mat-form-field appearance="outline" class="ml-3">
                    <mat-select  [(value)]="selected" placeholder="In Progress">
                      <mat-option value="progress">In Progress</mat-option>
                      <mat-option value="close">Close</mat-option>
                      <mat-option value="pandding">Pandding</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="d-flex ml-3">
                      <h2 class="size_h2_css">Created time</h2>
                      <p class="text-muted ml-5 mt-2">20-July-2020, 12:20 PM</p>
                  </div>
                  <div class="d-flex ml-3">
                    <h2 class="size_h2_css">Reported by</h2>
                    <p class="text-muted ml-5 mt-2">Salim Khan</p>
                </div>
                <div class="d-flex ml-3">
                    <h2 class="size_h2_css">First action time</h2>
                    <p class="text-muted ml-4 mt-2">20-July-2020, 12:20 PM</p>
                </div>
                <div class="d-flex ml-3">
                    <h2 class="size_h2_css">Updated time</h2>
                    <p class="text-muted ml-5 mt-2">20-July-2020, 12:20 PM</p>
                </div>
            </div>
        </div>
    </div>
</div>