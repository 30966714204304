import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiServiceService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  // Create
  create(action:any,data:any): Observable<any> {
    return this.http.post(environment.apiUrl+action, data)
      .pipe(
        catchError(this.error)
      )
  }

  // Read
  findAll(url:any) {
    return this.http.get(environment.apiUrl+url);
  }

  // Update
  updateTask(action:any,data:any): Observable<any> {
    let API_URL = `${environment.apiUrl+action}`;
    return this.http.put(API_URL, data, { headers: this.headers }).pipe(
      catchError(this.error)
    )
  }

  // Delete
  delete(action:any): Observable<any> {
    var API_URL = `${environment.apiUrl+action}`;
    return this.http.delete(API_URL).pipe(
      catchError(this.error)
    )
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}