import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './popupcmt.component.html',
  styleUrls: ['./home.component.css']
})
export class PopupcmtComponent implements OnInit {

  name = 'Angular';
  userId = 86;
  postId = 889;
  reply_by = 1;
  postData:any;
  showMore = false;
  message:any;
  responses:any;
  selected = false;
  tagTo = '';
  profilePost:any;
  isValid:any;
  imageUrl = environment.imageUrl;
  constructor(
    private http : HttpClient,) {
    }


    async postfindone() {
      // this.message = "Fetching..error";
      // console.log("Fetching---------error");
      this.responses = "";
      this.responses = await this.http
        .get<any>(environment.apiUrl+'post/findone/'+this.postId +'/' +this.userId)
        .pipe()
        .toPromise();
        console.log("findone get data",this.responses)
      this.message = "Fetched done";
    }

    likeUnlike(timelineId:any){
      var data = {
        timeline_id : timelineId,
        user_id : this.userId
      
      }
      console.log("hehehehe",timelineId)
      this.http.post(environment.apiUrl+'timeline/like/',data)
      .subscribe(datas => {
      console.log("like after data post " ,datas);
      });
      }

    // onSubmit(data:any,timeline_id:any){
    //   data.comment_by =  this.userId;
    //   data.timeline_id = timeline_id;
    //   console.log("COMMEMNRTNTNTNTTNTN",data)
    //   this.http.post(environment.apiUrl+'comment/post/', data)
    //     .subscribe(datas => {  
    //       console.log("findone data post",datas)      
    //   });
    // }

    // commentSubmit(data:any){
    //   data.reply_by = this.userId;
    //   // console.log("comment on successful",data)
    //   this.http.post(environment.apiUrl+'comment/reply/', data)
    //     .subscribe(datas => {  
    //       console.log("comment on successful",datas)      
    //   });
    // }

    
    commentLikes(commentId:any,key:any){
        // console.log("hehehehe",timelineId)
        this.http.post(environment.apiUrl+'commentaLikeUnlike/'+commentId+'/'+this.userId,{})
        .subscribe((datas:any) => {
          // this.liked = false;
          console.log('------jahangirKhan--------',datas);
  
          if(datas.status == true){
            console.log('---data----',this.responses.data.post[key].commentLikes);
            // this.postelement[key].commentLikes = datas.data
            if(datas.msg == "comment Unlike successfully"){
              this.responses.data.post[key].commentLikes = [];
               
            }else{
              this.responses.data.post[key].commentLikes = datas.data
            }
  
          }else{
            // this.postelement.myLiked = datas.data;
          }
        });
        }


    usernameIDshow(tag:any){
      this.tagTo = '@'+tag;
      this.isValid = true;
    }

    onSubmit(data:any,timelineId:any){
      data.comment_by =  this.userId;
      data.timeline_id =  timelineId;
      console.log("COMMEMNRTNTNTNTTNTN",data)
      this.http.post(environment.apiUrl+'comment/post/', data)
        .subscribe(datas => {  
          console.log("findone data post",datas)      
      });
    }

    commentSubmit(data:any){
      data.comment_by =  this.userId;
      data.reply_by = this.reply_by;
      // console.log("comment on successful",data)
      this.http.post(environment.apiUrl+'comment/reply/1', data)
        .subscribe(datas => {  
          console.log("comment on successful",datas)      
      });
    }

    showDiv = {
      previous : false,
    }
    
  ngOnInit(): void {
    this.userId;
    this.postData = this.postfindone();
    
}

}
