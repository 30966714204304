<mat-toolbar class="shadow-sm fixed-top navbar_Mobile_hide" style="background-color: white;">
    <mat-toolbar-row>
        <a href="group-chat" class="text-decoration-none">
            <mat-icon class="icon_mobile_size mr-2 mt-1 text-dark border-0" matCellDefix>keyboard_arrow_left</mat-icon>
        </a>
        <img class="mobile_size_navbar" src="../../assets/img/pexels-chloe-kala-1043473.png">
        <h3 class="font_h3_css ml-2">Salim Khan</h3>
      <span class="example-spacer"></span>
      <div class="float-right mt-1">
        <img width="15px" height="17px" class="" src="../../assets/img/Icon ionic-md-call.png" />
        <img width="25px" height="17px" class="ml-4" src="../../assets/img/Icon awesome-video.png" />
    </div>  
    </mat-toolbar-row>
  </mat-toolbar>
<div class="example1 mobil_mt_css">
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Chat">
            <div class="text-center mt-3">
                <img width="77px" height="77px" class="rounded-circle" src="https://i.pinimg.com/736x/dc/f6/d0/dcf6d047ce6df495c54f832e1adc6e67.jpg">
                <h2>Ladakh camping event</h2>
                <p class="text-muted">The quick, brown fox jumps over a lazy..... dog.<br> DJs flock by when MTV ax quiz prog.uiz graced by fox.</p>
                <a href="" class="text-decoration-none text-muted">
                    Amit joined this event
                </a>
            </div>
            <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
                <div class="container p-0">
                    <div fxFlex="100%" fxFlex.xs="100%" class="">
                        <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                            <div class="">
                                <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-cottonbro-5192878.png" />
                            </div>
                            <div class="ml-3">
                                <h5 class="text-muted">Amit, 02:11 PM</h5>
                                <div class="cheat_p_css">
                                    <p class="css_p_tag1 p-2 ml-2">
                                        Hello...
                                    </p>
                                </div>
                                <div class="cheat_p_css">
                                    <p class="css_p_tag1 p-2 ml-2">
                                        How are you?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- right side comment start -->
        <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
            <div class="container">
                <div fxFlex="100%" fxFlex.xs="100%" style="height: 120px;">
                    <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                        <div class="ml-3 ml-auto float-right">
                            
                            <div class="cheat_p_css1">
                                <p class="text-white text-right css_p_tag1 p-2 ml-2">
                                    Hello...
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="text-right" style="position: relative; bottom: 78px;">
                        <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-john-stelios.png" />
                    </div>
                </div>
            </div>
        </div>
        
        <!-- right side comment end -->
        </mat-tab>
        <mat-tab label="Media">
            <div class="container mt-3">
                <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center">
                    <div fxFlex="33%" fxFlex.xs="33%" class="">
                        <img class="group_img_mobile group_img_mobile1" src="../../assets/img/rider-1646754.png">
                    </div>
                    <div fxFlex="33%" fxFlex.xs="33.33%" class="">
                        <img class="group_img_mobile group_img_mobile1" src="../../assets/img/bicycle-384566_1920.png">
                    </div>
                    <div fxFlex="33%" fxFlex.xs="33%" class="">
                        <img class="group_img_mobile group_img_mobile1" src="../../assets/img/rider-1646750.png">
                    </div>
                </div>
            </div>
        </mat-tab>
      </mat-tab-group>
</div>
<mat-form-field appearance="outline" class="w-100 rounded-0">
    <input matInput placeholder="Type a message here..." class="Input_css" />
    <img width="23px" height="23px" matPrefix class="icon_comment" src="../../assets/img/Icon feather-smile.png" />
    <img width="23px" height="18px" matSuffix class="icon_comment" src="../../assets/img/Icon awesome-images.png" />
</mat-form-field>
