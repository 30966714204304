import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-navs',
  templateUrl: './side-navs.component.html',
  styleUrls: ['./side-navs.component.css']
})
export class SideNavsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
