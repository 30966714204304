<div class="triangle-up ml-2"></div>

<div  class="example1 Regular shadow mobile_size_popup col-sm-12 col-md-12 col-12">
    <div *ngFor="let notifications of notification" class="d-flex ml-3 mt-3" style="top: 0 !important;">
       <pre></pre>
        <ng-container *ngIf="notifications.notifiable_type === 'like';else second">
            1
        </ng-container>
        <ng-template #second>
        
            <ng-container *ngIf="notifications.notifiable_type === 'follow_request';else third">
                2
            </ng-container>
        </ng-template>
        <ng-template #third>
        
            <ng-container *ngIf="notifications.notifiable_type === 'follow_request';else third">
                2
            </ng-container>
        </ng-template>
        <ng-template #fourth>
            {{notifications.notifiable_type | json}}
        </ng-template>
       
        
    </div>
    <!-- <div *ngFor="let notifications of notification" class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="{{imageUrl}}story_images/{{notifications.sender.image}}"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1 d-flex">
                <span class="ml-2 font_size">
                    <b>{{notifications.sender.username}}</b>
                </span>
                <p class="ml-2 font_size1 text-muted">liked your photo..2d</p>
            </div> 
            <div class="ml-4">
                <img width="40" height="40" class="ml-5" src="{{imageUrl}}story_images/{{notifications.timeline.images.image}}">
            </div>
        </div>
    </div> -->
    <!-- <div class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7BbmnGYwLkM4bl6yE25A0V0b1H2Qxm1IgPA&usqp=CAU"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1">
                <span class="ml-2 font_size">
                    <b>UX_RISHI</b>
                </span>
                <p class="ml-2 font_size1 text-muted">started following you...2d</p>
            </div> 
            <div class="ml-5">
                <button class="ml-5 mt-1  JOIN_btn44" mat-button>
                    <span class="JOIN_btn333">Following</span>
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7BbmnGYwLkM4bl6yE25A0V0b1H2Qxm1IgPA&usqp=CAU"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1">
                <span class="ml-2 font_size">
                    <b>UX_RISHI</b>
                </span>
                <p class="ml-2 font_size1 text-muted">started following you...2d</p>
            </div> 
            <div class="ml-5">
                <button class="ml-5 mt-1  JOIN_btn2" mat-button>
                    <span class="JOIN_btn33">Follow</span>
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7BbmnGYwLkM4bl6yE25A0V0b1H2Qxm1IgPA&usqp=CAU"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1">
                <span class="ml-2 font_size">
                    <b>UX_RISHI</b>
                </span>
                <p class="ml-2 font_size1 text-muted">started following you...2d</p>
            </div> 
            <div class="ml-5">
                <button class="ml-5 mt-1  JOIN_btn44" mat-button>
                    <span class="JOIN_btn333">Following</span>
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7BbmnGYwLkM4bl6yE25A0V0b1H2Qxm1IgPA&usqp=CAU"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1">
                <span class="ml-2 font_size">
                    <b>UX_RISHI</b>
                </span>
                <p class="ml-2 font_size1 text-muted">started following you...2d</p>
            </div> 
            <div class="ml-5">
                <button class="ml-5 mt-1  JOIN_btn2" mat-button>
                    <span class="JOIN_btn33">Follow</span>
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex ml-3 mt-3" style="top: 0 !important;">
        <img width="42" height="42" class="rounded-circle mt-1" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ7BbmnGYwLkM4bl6yE25A0V0b1H2Qxm1IgPA&usqp=CAU"> 
        <div class="d-flex" style="border-bottom: 1px solid #e9e9e9;">
            <div class="mt-1">
                <span class="ml-2 font_size">
                    <b>UX_RISHI</b>
                </span>
                <p class="ml-2 font_size1 text-muted">started following you...2d</p>
            </div> 
            <div class="ml-5">
                <button class="ml-5 mt-1  JOIN_btn2" mat-button>
                    <span class="JOIN_btn33">Follow</span>
                </button>
            </div>
        </div>
    </div> -->   
</div>    