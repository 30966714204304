

<div class="mt-5">
    <a href="edit/profile" class="text-decoration-none">
        <h2 class="text-dark">Edit profile</h2>
    </a>
    <a href="change/password" class="text-decoration-none">
        <h3 class="text-dark mobile_size_h5" style="color: #45a1de;">Change Password</h3>
    </a>
</div>
