import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from './../../environments/environment';
@Component({
  selector: 'app-archieved',
  templateUrl: './archieved.component.html',
  styleUrls: ['./archieved.component.css']
})
export class ArchievedComponent implements OnInit {
  name = 'Angular';
  userId = 1;
  posted_by = 1;
  postData:any;
  message:any;
  searchMenu:any;
  response:any;
  imageUrl = environment.imageUrl;
  constructor(
    private http : HttpClient,) {}
  async archiveData() {
      this.message = "Fetching..";
      console.log("Fetching..");
      this.response = "data";
      console.log("data");
      this.response = await this.http
        .get<any>(environment.apiUrl+'findArchieved/'+this.posted_by)
        .pipe()
        .toPromise();
        console.log("archived view get data",this.response)
      this.message = "Fetched";
    }
  ngOnInit(): void {
      this.userId;
      this.postData = this.archiveData();
    }
}



