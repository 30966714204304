<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6%" fxLayoutAlign="center">
  <div fxFlex="25%" class="bg-color">
    <app-side-nav></app-side-nav>
</div>
      <div fxFlex="75%">
          <div class="col-lg-4 col-12 float-right mt-4">
            <div class="d-flex text-center image-css">
                <div class="">
                   <img width="42px" height="42px" class="icon ml-1 mt-1" src="../../assets/img/Group 228.png">
                </div>
                <div class="ml-4">
                    <img width="42px" height="42px" class="icon ml-1 mt-1" src="../../assets/img/Group 127.png">
                </div>
                <div class="ml-4">
                    <img width="42px" height="42px" class="icon ml-1 mt-1" src="../../assets/img/Group 128.png">
                </div>
                <div class="ml-4">
                    <img width="42px" height="42px" class="icon  ml-1 mt-1" src="../../assets/img/Group 231.png">
                </div>
            </div>
        </div>
        <div class="container">
          <div class="col-lg-12 col-md-12 col-12">
            <form #userPost="ngForm" (ngSubmit)="onSubmit(userPost.value)">
            <mat-horizontal-stepper linear labelPosition="bottom" #stepper>
              <mat-step  [editable]="isEditable">
                <div  class="mt-3">
                  
                  <ng-template matStepLabel class="step_size">STEP 1</ng-template>
                  
                    <h2>Create Event</h2>
                 
                
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    <mat-form-field  fxFlex="90%"  appearance="outline">
                      <input name="event_name" (change)="checkValidation(userPost.value)" ngModel matInput class="rounded-0"   placeholder="Event Name" required>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    <mat-form-field fxFlex="40%" color="accent" appearance="outline">
                      <input matInput placeholder="Start date" (change)="checkValidation(userPost.value)" name="start_date" ngModel matInput required [matDatepicker]="picker2">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" color="accent" appearance="outline">
                      <input matInput placeholder="End Date"(change)="checkValidation(userPost.value)" name="end_date" ngModel matInput required  [matDatepicker]="picker3">
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3 color="primary"></mat-datepicker>
                    </mat-form-field>
                   
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    
                    <mat-form-field fxFlex="40%" class="" appearance="outline">
                      <input matInput  #input placeholder="Start time" (change)="checkValidation(userPost.value)" name="start_time" ngModel matInput required>
                    </mat-form-field>
                    <mat-form-field fxFlex="40%" class="" appearance="outline">
                      <input matInput  #input placeholder="End time" name="end_time" ngModel matInput required>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" class="float-right button_css_" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    <div class="float-right">
                      <button mat-raised-button>Cancel</button>
                      <button mat-raised-button class="ml-4" color="primary" [disabled]="isFirstDisabled" matStepperNext>Continue</button> 
                    </div>
                  </div>
                </div>
              </mat-step>

              <!-- STEP 1 END -->

              <!-- STEP 2 START -->

              <mat-step   [editable]="isEditable">
                <div >
                  <ng-template matStepLabel>STEP 2</ng-template>

                  <h2>Event Details</h2>

                  <div fxLayout="row" class="mt-4" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="left">
                    <mat-form-field fxFlex="44%" appearance="outline">
                      <mat-select   placeholder="Event day type"  name="event_day_type" ngModel matInput required>
                        <mat-option value="single" (click)="checkValidationSec(userPost.value)">Single</mat-option>
                        <mat-option value="multi" (click)="checkValidationSec(userPost.value)">Multi</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="44%" appearance="outline">
                      <mat-select placeholder="Event Charge" name="event_charge" ngModel matInput required>
                        <mat-option value="paid" (click)="checkValidationSec(userPost.value)">paid</mat-option>
                        <mat-option value="free" (click)="checkValidationSec(userPost.value)">free</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" class="mt-3" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign="left">
                    <mat-form-field fxFlex="40%" appearance="outline">
                      <mat-select   placeholder="Start Location" name="start_location" ngModel matInput required>
                        <mat-option value="One" (click)="checkValidationSec(userPost.value)">One</mat-option>
                        <mat-option value="Two" (click)="checkValidationSec(userPost.value)">Two</mat-option>
                      </mat-select>
                    </mat-form-field>
                    
                    <img width="37px" height="27px" class="image_sks"  src="../../assets/img/Group 227.png">
                    
                    <mat-form-field fxFlex="40%" appearance="outline" class="">
                      <mat-select   placeholder="End Location" name="end_location" ngModel matInput required>
                        <mat-option value="One" (click)="checkValidationSec(userPost.value)">One</mat-option>
                        <mat-option value="Two" (click)="checkValidationSec(userPost.value)">Khan</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" class="mt-3" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    <mat-form-field fxFlex="44%" appearance="outline">
                      <mat-select   placeholder="Event type" name="event_type" ngModel matInput required>
                        <mat-option value="public" (click)="checkValidationSec(userPost.value)">Public</mat-option>
                        <mat-option value="private" (click)="checkValidationSec(userPost.value)">Private</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                 
                  <div fxLayout="row" fxLayout.xs="column" class="float-right button_css_" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                    <div class="float-right">
                      <button mat-raised-button>Cancel</button>
                      <button mat-raised-button class="ml-4 " [disabled]="isSecondDisabled" color="primary" matStepperNext>Continue</button> 
                    </div>
                  </div>
                </div>
              </mat-step>

              <!-- STEP 2 END -->

              <!-- STEP 3 START-->

              <mat-step>
                <ng-template matStepLabel>STEP 3</ng-template>
                <h2>Upload Image</h2>
                <div class="input-group col-lg-6 col-md-4 col-12">
                  <input class="form-control py-2" type="file" name="image" (change)="checkValidationThird(userPost.value)" multiple required><br>
                  <span style="color: red;">Image required</span>
              </div>
              <div fxLayout="row" fxLayout.xs="column" class="float-right button_css_" fxLayoutWrap fxLayoutGap="10%" fxLayoutAlign="left">
                <div class="float-right">
                  <button mat-raised-button>Back</button>
                  <button mat-raised-button type="submit" [disabled]="isThirdDisabled" class="ml-4" color="primary">Upload</button> 
                </div>
              </div>
              </mat-step>

              <!-- STEP 3 END -->

            </mat-horizontal-stepper>

          </form>
          </div>
        </div>
    </div>
    
</div>

<script>
  angular.module('demoApp', ['ngMaterial','md.time.picker'])

.config(['$mdThemingProvider', function ($mdThemingProvider) {
    'use strict';

    $mdThemingProvider.theme('default')
      .primaryPalette('blue');
}])

.controller('demoAppController', ['$scope','$mdpTimePicker', function($scope) {

  $scope.time = {
    twelve: new Date(),
    twentyfour: new Date()
  };

  $scope.message = {
    hour: 'Hour is required',
    minute: 'Minute is required',
    meridiem: 'Meridiem is required'
  }

}]);

</script>