<mat-toolbar class="shadow-sm fixed-top navbar_Mobile_hide" style="background-color: white;">
    <mat-toolbar-row>
        <a href="chat-message" class="text-decoration-none">
            <mat-icon class="icon_mobile_size mr-2 mt-1 text-dark border-0" matCellDefix>keyboard_arrow_left</mat-icon>
        </a>
        <img class="mobile_size_navbar" src="../../assets/img/pexels-chloe-kala-1043473.png">
        <h3 class="font_h3_css ml-2">Salim Khan</h3>
      <span class="example-spacer"></span>
      <div class="float-right mt-1">
        <img width="15px" height="17px" class="" src="../../assets/img/Icon ionic-md-call.png" />
        <img width="25px" height="17px" class="ml-4" src="../../assets/img/Icon awesome-video.png" />
    </div>  
    </mat-toolbar-row>
  </mat-toolbar>

<!-- chat design start -->

<div class="example1 mobile_size_mt">
    <!-- left side comment  start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" class="">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="">
                        <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-cottonbro-5192878.png" />
                    </div>
                    <div class="ml-3">
                        <h5 class="text-muted">Amit, 02:11 PM</h5>
                        <div class="cheat_p_css">
                            <p class="text-muted css_p_tag1 p-2 ml-2">
                                hy
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- left side comment  end-->

    <!-- right side comment start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" style="height: 120px;">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="ml-3 ml-auto float-right">
                        <div class="text-right">
                            <h5 class="text-muted">Amit, 02:11 PM</h5>
                        </div>
                        <div class="cheat_p_css1">
                            <p class="text-white text-right css_p_tag1 p-2 ml-2">
                                hello
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-right laptop_size_css">
                    <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-john-stelios.png" />
                </div>
            </div>
        </div>
    </div>

    <!-- right side comment end -->

    <!-- left side comment  start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" class="">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="">
                        <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-cottonbro-5192878.png" />
                    </div>
                    <div class="ml-3">
                        <h5 class="text-muted">Amit, 02:11 PM</h5>
                        <div class="cheat_p_css">
                            <p class="text-muted css_p_tag1 p-2 ml-2">
                                how are you
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- left side comment  start -->

    <!-- right side comment start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" style="height: 120px;">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="ml-3 ml-auto float-right">
                        <div class="text-right">
                            <h5 class="text-muted">Amit, 02:11 PM</h5>
                        </div>
                        <div class="cheat_p_css1">
                            <p class="text-white text-right css_p_tag1 p-2 ml-2">
                                I am fine
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-right laptop_size_css">
                    <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-john-stelios.png" />
                </div>
            </div>
        </div>
    </div>

    <!-- right side comment end -->

    <!-- left side comment  start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" class="">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="">
                        <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-cottonbro-5192878.png" />
                    </div>
                    <div class="ml-3">
                        <h5 class="text-muted">Amit, 02:11 PM</h5>
                        <div class="cheat_p_css">
                            <p class="text-muted css_p_tag1 p-2 ml-2">
                                how are you
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- left side comment  start -->

    <!-- right side comment start -->
    <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
        <div class="container">
            <div fxFlex="100%" fxFlex.xs="100%" style="height: 120px;">
                <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                    <div class="ml-3 ml-auto float-right">
                        <div class="text-right">
                            <h5 class="text-muted">Amit, 02:11 PM</h5>
                        </div>
                        <div class="cheat_p_css1">
                            <p class="text-white text-right css_p_tag1 p-2 ml-2">
                                I am fine The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-right laptop_size_css">
                    <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-john-stelios.png" />
                </div>
            </div>
        </div>
    </div>

    <!-- right side comment end -->
</div>
<mat-form-field appearance="outline" class="w-100 rounded-0">
    <input matInput placeholder="Type a message here..." class="Input_css" />
    <img width="23px" height="23px" matPrefix class="icon_comment" src="../../assets/img/Icon feather-smile.png" />
    <img width="23px" height="18px" matSuffix class="icon_comment" src="../../assets/img/Icon awesome-images.png" />
</mat-form-field>

<!-- chat design end -->