import { Component, OnInit } from '@angular/core';


export interface PeriodicElement {

  hashtag: any;
  serialnumber: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { hashtag: '#aarif_solankey12', serialnumber: '00001'},
  { hashtag: '#aarif_solankey12', serialnumber: '00001'},
  { hashtag: '#aarif_solankey12', serialnumber: '00001'},
  { hashtag: '#aarif_solankey12', serialnumber: '00001'},
  { hashtag: '#aarif_solankey12', serialnumber: '00001'},
  
];



@Component({
  selector: 'app-admin-hashtag',
  templateUrl: './admin-hashtag.component.html',
  styleUrls: ['./admin-hashtag.component.css']
})
export class AdminHashtagComponent implements OnInit {
  checked = false;
  indeterminate = false;
  displayedColumns: string[] = ['hashtag', 'serialnumber'];
  dataSource = ELEMENT_DATA;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  ngOnInit(): void {
  }
  
}
