import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  data:any;
  datas:any;
  currentUserId = 1;
  id:any;
  profilePost:any;
  imageUrl = environment.imageUrl;
  constructor(private http : HttpClient) { }

  ngOnInit(): void {
    this.http.get(environment.apiUrl+'user/getMyProfile/1/29')
    .subscribe(datas => {
      this.profilePost = datas; 
      console.log("profile get data har",this.profilePost);
    
  });
  }
  
  onSubmit(){
    this.http.put(environment.apiUrl+'user/1',this.currentUserId )
      .subscribe(datas => {
       console.log("update har",datas);
    });
   
  }
}
