  <div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="center">
      <div fxFlex="25%" class="ddddddddddddd">
        <div  class="p-0 pt-3">
            <div class="text-center">
              <img width="80px" height="80px" src="../../../assets/img/pexels-chloe-kala-1043473.png">
              <h2 class="mt-2">SalimKhan_774</h2>
            </div>
            <div class="container-fluid bg_back">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/dashboard.png">
                <a href="admin/admin-landing" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white ">Dashboard</h4>
                </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/user.png">
                <a href="admin/admin-userlist" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white">Users List</h4>
                </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/paper-plane.png">
                <a href="" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white">Total Posts</h4>
                </a>
              </div>
            </div>
            
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/calendaricon.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">All Events</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/hashtag.png">
                <a href="admin/admin-hashtag" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Add Hashtag</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/file.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Reported Account</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/help.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">User Queries</h4>
              </a>
              </div>
            </div>
          </div>
      </div>
    <div fxFlex="75%">
        <h2 class="mt-4 vl">Overview</h2>
        <div class="container mt-5">  
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="left">
                <div class="" fxFlex="33.33%" >
                    <mat-card class="card-bg-color " role="button">
                        <h3 class="text-dark">Total User</h3>
                        <h1 class="text-dark">42k</h1>
                        <img class="icon_css_img" width="80px" height="60px" src="../../assets/img/Icon awesome-user-friends.png">
                    </mat-card>
                </div>
                <div class="" fxFlex="33.33%" >
                    <mat-card class="card-bg-color2 " role="button">
                        <h3 class="text-dark">Total Post</h3>
                        <h1 class="text-dark">84k</h1>
                        <img class="icon_css_img" width="80px" height="60px" src="../../assets/img/paper-planeicon.png">
                    </mat-card>
                </div>
                <div class="" fxFlex="33.33%"  >
                    <mat-card class="card-bg-color3 " role="button">
                        <h3 class="text-dark">Total Event</h3>
                        <h1 class="text-dark">6,30k</h1>
                        <img class="icon_css_img" width="80px" height="60px" src="../../assets/img/Icon awesome-user-friends.png">
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
