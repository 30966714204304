<div class="">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="center">
        <div class="" fxFlex="50%">
            <img width="100%" height="590" class="mt-3" src="../../../assets/img/g10.png" />
        </div>
        <div class="" fxFlex="50%">
            
            <div class="mt-1" fxFlex="100%">
                <div class="text-center">
                   <img width="269px" height="180" src="../../assets/img/exploreologo.png">
                   <h2 class="font_size_css mt-4">Admin Login</h2>
                   <p class="text-muted mt-4">To reset your password send the OTP on Registered<br> number (*******645)</p>
                </div>
                <div class="text-center mt-5">
                    <button class="w-75" mat-raised-button style="background-color: #1b9ae2; color: white;border-radius: 9px;">SEND OTP</button>
                </div>
            </div>
            
        </div>
    </div>
  </div>
  