<mat-toolbar class="fixed-top shadow-sm p-3 mb-5">
    <div class="container">
        <a routerLink="/">
            <img width="127px" height="67px" src="../../assets/img/navbarlogo.png" />
        </a>
        <div class="form-group has-search w-25">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" placeholder="Search" />
        </div>

        <span class="example-spacer"></span>
        <div class="float-right mt-2">
            <a routerLink="/about-us">
                <img width="22px" height="22px" class="mobile_size_profile" src="../../assets/img/home (1)ss.png" />
            </a>
            <a routerLink="/prices">
                <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 547.png" />
            </a>
            <a routerLink="/start-page">
                <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 548.png" />
            </a>
            <a routerLink="/offer">
                <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 549.png" />
            </a>
        </div>
    </div>
</mat-toolbar>
