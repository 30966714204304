<div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign="center">
    <div fxFlex="25%">
        <app-side-nav></app-side-nav>
    </div>
    <div fxFlex="75%" class="example">
        <div class="container">
            <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="7%" fxLayoutAlign="center">
    
                <div fxFlex="50%" [matMenuTriggerFor]="searchMenu" class="form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control rounded-0" placeholder="Search" />
                </div>
                <div fxFlex="50%" class="text-right mt-3 mr-4">
                    <a routerLink="/about-us">
                        <img width="23px" height="23px" class="mobile_size_profile" src="../../assets/img/home (1)ss.png" />
                    </a>
                    <a routerLink="/prices">
                        <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 547.png" />
                    </a>
                    <a routerLink="/start-page">
                        <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 548.png" />
                    </a>
                    <a routerLink="/offer">
                        <img width="40px" height="40px" class="mobile_size_profile ml-4" src="../../assets/img/Group 549.png" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let archieved of response.data" class="col-lg-4 col-sm-4 col-4 containernwe">
                    <img width="299px" height="320px" class="mobile_size_img mt-3" src="https://wallpapercave.com/wp/wp2691049.jpg">
                            
                    <div class="overlay" >
                        <div class="text d-flex">
                          <img class="" type="button"  width="30px" height="30px"  src="../../assets/img/like.svg" />
                           <!-- <h2 class=" ml-2 mt-1">{{archieved.likes.length}}</h2> -->
    
                          <img class=" ml-5 mt-1" type="button" width="24px" height="24px" src="../../assets/img/commment.svg" />
                           <!-- <h2 class=" ml-2 mt-1">{{archieved.comments.length}}</h2> -->
                        </div>
                      </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

