import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-save-post',
  templateUrl: './save-post.component.html',
  styleUrls: ['./save-post.component.css']
})
export class SavePostComponent implements OnInit {

  name = 'Angular';
  userId = 1;
  posted_by = 1;
  postData:any;
  message:any;
  response:any;
  imageUrl = environment.imageUrl;

  constructor(private http : HttpClient,) { 
    this.response={data:{comments:[]}}
  }
  async savedData() {
    this.message = "Fetching..";
    console.log("Fetching..");
    this.response = "data";
    console.log("data");
    this.response = await this.http
      .get<any>(environment.apiUrl+'post/savedlist/'+this.userId)
      .pipe()
      .toPromise();
      console.log("archived view get data",this.response)
    this.message = "Fetched";
  }

  ngOnInit(): void {
    this.userId;
        this.postData = this.savedData();
  }

}
