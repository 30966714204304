<app-side-navs></app-side-navs>
<div style="background-color: #f5f5f5;margin-top: 64px;">
    <div class="container p-0" style="background-color: white;">
        <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
            <div class="border-left example chat_mobile_size" fxFlex.xs="100%" fxFlex="30%">
                <div class="container">
                    <div class="d-flex mt-4">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="16px" height="15px" src="../../assets/img/Icon feather-message-square.png" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="16px" height="15px" src="../../assets/img/Icon ionic-md-call1.png" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="23px" height="15px" src="../../assets/img/Icon awesome-video1.png" />
                        </div>
                    </div>
                </div>
                <div class="form-group has-search ml-2">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control rounded-0" placeholder="Search" />
                </div>
                <div class="d-flex border-bottom">
                    <a href="chat-message" class="text-muted ml-3 text-decoration-none">Chat</a>
                    <a href="group-chat" class="ml-3 chat_border_css text-dark text-decoration-none">Group Chat</a>
                </div>
                <div class="container-fluid bg_cheatbox_css p-0 pl-0">
                    <a href="mobile-group-chat" class="text-decoration-none">
                        <div class="d-flex mt-2 pt-3 ml-3">
                            <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png" />
                            <div class="">
                                <h5 class="ml-2 text-dark">Ladakh camp event</h5>
                                <p class="text-muted css_p_tag ml-2">The quick, brown fox jumps over a lazy.....</p>
                            </div>
                            <p class="text-muted float-right date_css mr-1">11:00 AM</p>
                        </div>
                    </a>
                </div>
                <div class="d-flex mt-2 ml-3">
                    <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png" />
                    <div class="">
                        <h5 class="ml-2">Abhishek Pandey</h5>
                        <p class="text-muted css_p_tag ml-2">The quick, brown fox jumps over a lazy.....</p>
                    </div>
                    <p class="text-muted float-right date_css mr-1">11:00 AM</p>
                </div>
            </div>
            <div fxFlex="70%" fxFlex.xs="50%" class="mobile_size_hide">
                <div class="container-fluid d-flex p-0 border-left border-bottom border-right" style="height: 69px;">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <h2 class="ml-4 mt-3">Ladakh camping event</h2>
                        
                    </div>
                    <div class="col-lg-6 col-md-6 mt-3 col-sm-6">
                        <div class="float-right mt-1">
                            <img width="15px" height="17px" class="" src="../../assets/img/Icon ionic-md-call.png" />
                            <img width="25px" height="17px" class="ml-4" src="../../assets/img/Icon awesome-video.png" />
                        </div>
                    </div>
                </div>
                <app-groupmb-chat></app-groupmb-chat>
            </div>
        </div>
    </div>
</div>
