import { Component, OnInit } from '@angular/core';
import { SocketioService } from '../socketio.service';
import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent implements OnInit {
  title = 'socket-frontend';
  socket:any;
  singleChat:any;
  userId = 1;
  groups:any;
  singleUserChats:any;
  value:any;
  message = '';
  constructor(
    private http:HttpClient,
    private socketService: SocketioService) {}
    getSingleChat(receiverId:any){

      this.http.get(environment.apiUrl+"chat-room/single/"+this.userId+'/'+receiverId).subscribe((res:any) => {
        this.singleUserChats = res;
      });
    }
  SendMessage(message:any,receiverId:any) {
    this.value = message;
    console.log('-------Socket----',this.value);
    this.socket = io(environment.SOCKET_ENDPOINT);
    var data = {
      message:this.value.message,
      sender_id:this.userId,
      event_id:0,
      receiver_id:receiverId
    }
    console.log("----send successfulyy---",message.message)
    this.http.post(environment.apiUrl+"send/message",data).subscribe((res:any) => {
      // this.singleUserChats = res;
      console.log("----send successfulyy---",res)
    });
  var a = this.socket.emit('my message', data);
  console.log("TEST",a);
  this.message = '';
  this.http.get(environment.apiUrl+"chat-room/single/"+this.userId+'/'+receiverId).subscribe((res:any) => {
    this.singleUserChats = res;
    console.log(this.singleUserChats);
  });
  this.socket.on(environment.SOCKET_ENDPOINT, (data: string) => {
    // console.log(data);
    
  });
  
 }  

 ngOnInit(): void {
   this.setupSocketConnection();
   console.log("SINGLE DATA",environment.apiUrl+'chat-room/get-all/'+this.userId)
   this.http.post(environment.apiUrl+'chat-room/get-all/'+this.userId,'').subscribe((data:any) =>{
    console.log("Mesaage",data);
    this.singleChat =data.data.chatsDetail;
    this.groups =data.data.groups;
  });
  }
  setupSocketConnection() {
    // this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket = io( environment.SOCKET_ENDPOINT, {'transports': ['websocket', 'polling']});

    this.socket.on('message-broadcast', (data: string) => {
    if (data) {
     const element = document.createElement('li');
     element.innerHTML = data;
     element.style.background = 'white';
     element.style.padding =  '15px 30px';
     element.style.margin = '10px';
     console.log("setupSocketConnection",data);
     }
   });
 }
}
