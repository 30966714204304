<app-side-navs></app-side-navs>
<div style="background-color: #f5f5f5;margin-top: 64px;" >
    <div class="container p-0" style="background-color: white;">
        <div fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
            <div class="border-left example chat_mobile_size " fxFlex.xs="100%" fxFlex="30%">
                <div class="container">
                    <div class="d-flex mt-4">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="16px" height="15px" src="../../assets/img/Icon feather-message-square.png" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="16px" height="15px" src="../../assets/img/Icon ionic-md-call1.png" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <img width="23px" height="15px" src="../../assets/img/Icon awesome-video1.png" />
                        </div>
                    </div>
                </div>
                <div class="form-group has-search ml-2">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control rounded-0" placeholder="Search" />
                </div>
                <div class="d-flex border-bottom">
                    <mat-tab-group mat-align-tabs="start" class="w-100">
                        <mat-tab label="Chat">
                            
                            <div *ngFor="let chat of singleChat" class="container-fluid bg_cheatbox_css p-0 pl-0" data-toggle="tab" href="#menu1">
                               
                                <div class="d-flex mt-2 pt-3 ml-3" (click)="getSingleChat(chat.participant.id)">
                                        <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png" />
                                        <div class="">
                                            <h5 class="ml-2 text-dark">
                                                <b>{{chat.participant.username}}</b>
                                            </h5>
                                            <p class="text-muted css_p_tag ml-2">{{chat.message}}</p>
                                        </div>
                                        <div class="ml-auto">
                                            <p class="text-muted date_css mr-1">{{ chat.createdAt | date:'shortTime' }} </p>
                                        </div>
                                        
                                    </div>
                            </div>
                           
                            
                        </mat-tab> 
                        <mat-tab label="Group Chat">
                            <!-- <pre>
                                {{groups | json}}
                            </pre> -->
                            <div *ngFor="let group of groups" class="container-fluid bg_cheatbox_css p-0 pl-0">
                                
                                <a href="#groupchat" class="text-decoration-none">
                                    <div class="d-flex mt-2 pt-3 ml-3">
                                        <img width="40px" height="40px" src="../../assets/img/pexels-cottonbro-5192878.png" />
                                        <div class="">
                                            <h5 class="ml-2 text-dark">{{group.event_name}}</h5>
                                            <p class="text-muted css_p_tag ml-2">{{group.groupChat == null ? group.description : group.groupChat.message}}</p>
                                        </div>
                                        <div class="ml-auto">
                                            <p class="text-muted date_css mr-1">{{group.groupChat == null ? '' :  group.groupChat.createdAt | date:'shortTime' }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        </mat-tab>
                      </mat-tab-group>
                    <!-- <a href="chat-message" class="chat_border_css text-dark ml-3 text-decoration-none"></a>
                    <a href="group-chat" class="ml-3 text-muted text-decoration-none">Group Chat</a> -->
                </div>
                
            </div>
            <div *ngIf="singleUserChats != undefined && singleUserChats.status; else chatnotFound" fxFlex="70%" fxFlex.xs="50%" class="mobile_size_hide">
                <div class="container-fluid d-flex p-0 border-left border-bottom border-right" style="height: 69px;">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <h2 class="ml-4">{{singleUserChats.data.participant.username}}</h2>
                        <div class="d-flex activ_css_dot">
                            <div class="li">.</div>
                            <h4 class="text-muted">Active now</h4>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mt-3 col-sm-6">
                        <div class="float-right mt-1">
                            <img width="15px" height="17px" class="" src="../../assets/img/Icon ionic-md-call.png" />
                            <img width="25px" height="17px" class="ml-4" src="../../assets/img/Icon awesome-video.png" />
                        </div>
                    </div>
                </div>

                <!-- chat design start -->

                <div class="example1 mobile_size_mt">
                    <div *ngFor="let chatBy of singleUserChats.data.chatsDetail">
                        <div *ngIf="!chatBy.sentByyou" fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
                            <div class="container">
                                <div fxFlex="100%" fxFlex.xs="100%" class="">
                                    <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                                        <div class="">
                                            <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-cottonbro-5192878.png" />
                                        </div>
                                        <div class="ml-3">
                                            <h5 class="text-muted">Amit, {{chatBy.createdAt}}</h5>
                                            <div class="cheat_p_css">
                                                <p class="text-muted css_p_tag1 p-2 ml-2">
                                                    {{chatBy.message}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                        <div  *ngIf="chatBy.sentByyou" fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
                            <div class="container">
                                <div fxFlex="100%" fxFlex.xs="100%" style="height: 120px;">
                                    <div class="d-flex mt-2 pt-2 ml-4 cheat_css_bg">
                                        <div class="ml-3 ml-auto float-right">
                                            <div class="text-right">
                                                <h5 class="text-muted">You, {{chatBy.createdAt}}</h5>
                                            </div>
                                            <div class="cheat_p_css1">
                                                <p class="text-white text-right css_p_tag1 p-2 ml-2">
                                                    {{chatBy.message}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right laptop_size_css">
                                        <img width="43px" height="43px" class="mt-2" src="../../assets/img/pexels-john-stelios.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <form #sendMessageForm="ngForm" (keyup.enter)="SendMessage(sendMessageForm.value,singleUserChats.data.participant.id)">
                    <mat-form-field appearance="outline" class="w-100 rounded-0">
                    
                        <input ngModel name="message" [(ngModel)]="message" autocomplete="off"  [(ngModel)]="message" matInput placeholder="Type a message here..." class="Input_css" />
                        <img width="23px" height="23px" matPrefix class="icon_comment" src="../../assets/img/Icon feather-smile.png" />
                        <img width="23px" height="18px" matSuffix class="icon_comment" src="../../assets/img/Icon awesome-images.png" />
                    </mat-form-field>
                </form>
                

                <!-- chat design end -->
            </div>
            <ng-template #chatnotFound>Content to render when condition is false.</ng-template>

        </div>
    </div>
</div>
