import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestComponent } from './test/test.component';
import { ModuleWithProviders, NgModule} from "@angular/core";
import {  MatNativeDateModule } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { PopupComponent } from './home/popup.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { PopupcmtComponent } from './home/popupcmt.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { ProfilePostComponent } from './profile-post/profile-post.component';
import { SettingSidebarComponent } from './setting-sidebar/setting-sidebar.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoryComponent } from './story/story.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SendOtpComponent } from './send-otp/send-otp.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { SideNavsComponent } from './side-navs/side-navs.component';
import { CodeOtpComponent } from './code-otp/code-otp.component';
import { ArchievedComponent } from './archieved/archieved.component';
import { JoinEventComponent } from './join-event/join-event.component';
import { StorypopupComponent } from './story/storypopup.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminSendotpComponent } from './admin/admin-sendotp/admin-sendotp.component';
import { AdminVarifyotpComponent } from './admin/admin-varifyotp/admin-varifyotp.component';
import { LandingPageComponent } from './admin/landing-page/landing-page.component';
import { SidenavComponent } from './admin/sidenav/sidenav.component';
import { AdminUserlistComponent } from './admin/admin-userlist/admin-userlist.component';
import { AdminHashtagComponent } from './admin/admin-hashtag/admin-hashtag.component';
import { AdminReportedComponent } from './admin/admin-reported/admin-reported.component';
import { AdminUserqueriesComponent } from './admin/admin-userqueries/admin-userqueries.component';
import { AdminUserviewComponent } from './admin/admin-userview/admin-userview.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { GroupChatComponent } from './group-chat/group-chat.component';
import { ChatmbMessageComponent } from './chat-message/chatmb-message.component';
import { GroupmbChatComponent } from './group-chat/groupmb-chat.component';
import { MatVideoModule } from 'mat-video';
// import { SocketioService } from './socketio.service';
import { SavePostComponent } from './save-post/save-post.component';
// import { ChatComponent } from './chat/chat.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { JwPaginationModule } from 'jw-angular-pagination';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    HomeComponent,
    PopupComponent,
    GroupmbChatComponent,
    PopupcmtComponent,
    CreateEventComponent,
    ProfilePostComponent,
    SettingSidebarComponent,
    EditProfileComponent,
    SideNavComponent,
    StoryComponent,
    LoginComponent,
    SignupComponent,
    SendOtpComponent,
    ChangePwdComponent,
    SideNavsComponent,
    CodeOtpComponent,
    ArchievedComponent,
    JoinEventComponent,
    StorypopupComponent,
    AdminLoginComponent,
    AdminSendotpComponent,
    AdminVarifyotpComponent,
    LandingPageComponent,
    SidenavComponent,
    AdminUserlistComponent,
    AdminHashtagComponent,
    AdminReportedComponent,
    AdminUserqueriesComponent,
    AdminUserviewComponent,
    ChatMessageComponent,
    ChatmbMessageComponent,
    GroupChatComponent,
    SavePostComponent,
    // ChatComponent
    
  ],
  imports: [
      NgxPaginationModule,
      JwPaginationModule,
      Ng2SearchPipeModule,
      FormsModule,
      MatVideoModule,
      ReactiveFormsModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MatButtonModule,
      MatCarouselModule,
      MatSliderModule,
      MatAutocompleteModule,
      HttpClientModule,
      MatBadgeModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatStepperModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatTreeModule,
      MatNativeDateModule,
      FlexLayoutModule
  ],
  providers: [
    HttpClientModule,
    // SocketioService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
