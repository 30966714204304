import { GroupChatComponent } from './group-chat/group-chat.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { AdminUserviewComponent } from './admin/admin-userview/admin-userview.component';
import { AdminUserqueriesComponent } from './admin/admin-userqueries/admin-userqueries.component';
import { AdminReportedComponent } from './admin/admin-reported/admin-reported.component';
import { AdminHashtagComponent } from './admin/admin-hashtag/admin-hashtag.component';
import { AdminUserlistComponent } from './admin/admin-userlist/admin-userlist.component';
import { AdminVarifyotpComponent } from './admin/admin-varifyotp/admin-varifyotp.component';
import { AdminSendotpComponent } from './admin/admin-sendotp/admin-sendotp.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { JoinEventComponent } from './join-event/join-event.component';
import { ArchievedComponent } from './archieved/archieved.component';
import { CodeOtpComponent } from './code-otp/code-otp.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { SignupComponent } from './signup/signup.component';
import { SendOtpComponent } from './send-otp/send-otp.component';
import { LoginComponent } from './login/login.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SettingSidebarComponent } from './setting-sidebar/setting-sidebar.component';
import { ProfilePostComponent } from './profile-post/profile-post.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { PopupComponent } from './home/popup.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestComponent } from './test/test.component';
import { HomeComponent } from './home/home.component';
import { PopupcmtComponent } from './home/popupcmt.component';
import { LandingPageComponent } from './admin/landing-page/landing-page.component';
import { ChatmbMessageComponent } from './chat-message/chatmb-message.component';
import { GroupmbChatComponent } from './group-chat/groupmb-chat.component';
import { ChatComponent } from './chat/chat.component';
import { SavePostComponent } from './save-post/save-post.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'chat',
    component: ChatComponent
  },
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'popup',
    component: PopupComponent
  },
  {
    path: 'popup/comment',
    component: PopupcmtComponent
  },
  {
    path: 'event/create',
    component: CreateEventComponent
  },
  {
    path: 'profile/post',
    component: ProfilePostComponent
  },
  {
    path: 'edit/profile',
    component: EditProfileComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'send/otp',
    component: SendOtpComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'change/password',
    component: ChangePwdComponent
  },
  {
    path: 'code/otp',
    component: CodeOtpComponent
  },
  {
    path: 'archieved/post',
    component: ArchievedComponent
  },
  {
    path: 'join/event',
    component: JoinEventComponent
  },
  {
    path: 'admin/admin-login',
    component: AdminLoginComponent
  },
  {
    path: 'admin/admin-sendOTP',
    component: AdminSendotpComponent
  },
  {
    path: 'admin/admin-varifyOTP',
    component: AdminVarifyotpComponent
  },
  {
    path: 'admin/admin-landing',
    component: LandingPageComponent
  },
  {
    path: 'admin/admin-userlist',
    component: AdminUserlistComponent
  },
  {
    path: 'admin/admin-hashtag',
    component: AdminHashtagComponent
  },
  {
    path: 'admin/admin-reported',
    component: AdminReportedComponent
  },
  {
    path: 'admin/admin-userqueries',
    component: AdminUserqueriesComponent
  },
  {
    path: 'admin/admin-userview',
    component: AdminUserviewComponent
  },
  {
    path: 'chat-message',
    component: ChatMessageComponent
  },
  {
    path: 'group-chat',
    component: GroupChatComponent
  },
  {
    path: 'mobile-chat-message',
    component: ChatmbMessageComponent
  },
  {
    path: 'mobile-group-chat',
    component: GroupmbChatComponent
  },
  {
    path: 'save/post',
    component: SavePostComponent
  },
  {
    path: 'popup/path',
    component: PopupcmtComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
