import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-groupmb-chat',
  templateUrl: './groupmb-chat.component.html',
  styleUrls: ['./group-chat.component.css']
})
export class GroupmbChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
 
}
