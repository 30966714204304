import { Component, OnInit } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  apiBaseUrl= environment.apiUrl;
  baseUrl= environment .apiUrl;
  dataSource:any;
  phone_noLogin:any;
  data:any;
  localStorage:any;
  passwordLogin:any;
  loading = false;
  error:any;
  registerForm: any; 
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private http:HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { 
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate([' ']);
  }
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_no: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
  });
  }
  get f() { return this.registerForm.controls; }

 async onSubmit(data: any){
  this.submitted = true;
     // stop here if form is invalid
     if (this.registerForm.invalid) {
      return;
  }
  // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));


  await this.http.post(environment.apiUrl + "signup", data)
  
  .subscribe((datas:any) => {
    console.log("SignUp data",datas);
    if(datas.status){
      localStorage.setItem('currentUser', JSON.stringify(data));
    console.log('sss');
    this.router.navigate([''])
    }else{
      this.error = datas.message;
    }
  });
  }
  

  
  
}