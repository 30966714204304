import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-sendotp',
  templateUrl: './admin-sendotp.component.html',
  styleUrls: ['./admin-sendotp.component.css']
})
export class AdminSendotpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
