import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-event',
  templateUrl: './join-event.component.html',
  styleUrls: ['./join-event.component.css']
})
export class JoinEventComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
