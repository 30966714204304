<div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0%" fxLayoutAlign="center">
    <div fxFlex="25%" class="ddddddddddddd">
        <div  class="p-0 pt-3">
            <div class="text-center">
              <img width="80px" height="80px" src="../../../assets/img/pexels-chloe-kala-1043473.png">
              <a href="" class="text-decoration-none">
                <h2 class="mt-2 text-white">SalimKhan_774</h2>
              </a>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/dashboard.png">
                <a href="admin/admin-landing" class="text-decoration-none">
                    <h4 class="ml-4 mt-2 text-white ">Dashboard</h4>
                </a>
              </div>
            </div>
            <div class="container-fluid ">
                <div class="d-flex ml-3">
                  <img class="top_css_img icon_img_size" src="../../../assets/img/user.png">
                  <a href="admin/admin-userlist" class="text-decoration-none">
                      <h4 class="ml-4 mt-2 text-white ">User List</h4>
                  </a>
                </div>
              </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/paper-plane.png">
                <a href="" class="text-decoration-none">
                  <h4 class="ml-4 mt-2 text-white">Total Posts</h4>
                </a>
              </div>
            </div>
            
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/calendaricon.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">All Events</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid bg_back">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/hashtag.png">
                <a href="admin/admin-hashtag" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Add Hashtag</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/file.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">Reported Account</h4>
              </a>
              </div>
            </div>
            <div class="container-fluid">
              <div class="d-flex ml-3">
                <img class="top_css_img icon_img_size" src="../../../assets/img/help.png">
                <a href="" class="text-decoration-none">
                <h4 class="ml-4 mt-2 text-white">User Queries</h4>
              </a>
              </div>
            </div>
          </div>
    </div>
    <div fxFlex="75%" class="bbbbggg">
      <div class="d-flex">
        <h2 class="mt-4 vl ml-3">Add New Hashtag</h2>
        <div class="form-group has-search ml-auto w-25 mr-4">
          <span class="fa fa-search text-dark float-right form-control-feedback"></span>
          <input type="text" class="form-control" placeholder="Search hastag"/>
      </div>
      </div>
      <div class="d-flex">
        <div class="form-group has-search ml-3 mt-4 w-25 mr-4">
            <input type="text" class="form-control formcontrol rounded-0" placeholder="Add new hashtag"/>
        </div>
        <div class="form-group has-search ml-3 mt-4 w-25 mr-4">
            <input type="text" class="form-control formcontrol rounded-0" placeholder="Hashtag ID"/>
        </div>
      </div>
      <button class="download_bg rounded-0  mt-4 ml-3" mat-button>Create Hashtag</button>
 <!-- <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                class="example-margin"
                [(ngModel)]="checked"
                [(indeterminate)]="indeterminate"
                [labelPosition]="labelPosition"
                [disabled]="disabled">
              I'm a checkbox
            </mat-checkbox>
        </th> -->
        <section class="example-section">
            <mat-checkbox
                class="example-margin"
                [(ngModel)]="checked"
                [(indeterminate)]="indeterminate"
                [labelPosition]="labelPosition"
                [disabled]="disabled">
            </mat-checkbox>
          </section>
        <table mat-table [dataSource]="dataSource" class="bbbbggg ml-5">
            <!-- Weight Column -->
            <ng-container matColumnDef="hashtag">

              <th mat-header-cell *matHeaderCellDef class="text-dark"> 
               <b>Hashtag</b>   
            </th>
              <td mat-cell *matCellDef="let element" class="text_td_color"> {{element.hashtag}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="serialnumber">
              <th mat-header-cell *matHeaderCellDef class="text-dark"> 
                  <b>Serial Number</b>
                   </th>
              <td mat-cell *matCellDef="let element"> {{element.serialnumber}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </div>
</div>