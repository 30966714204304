<div class="container">
    <div fxLayout.xs="column" fxLayoutGap="%"fxLayoutAlign.xs="center">
       <mat-card class="example-card col-sm-12 col-md-12 col-12 mx-auto">
           <img class="mx-auto imgc" mat-card-image src="../../assets/img/exploreologo.png" >
           <div class="container">
           <form class="">
               <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                 <input class="" matInput type="text" class="form-control rounded-0 hi"  placeholder="Phone number, or email">
               </div>
                 <button class="rounded-0 bbc text-white mt-3" mat-raised-button >SENT OTP</button>
                <div class="row mt-3">
                   <div class="col"><hr class="sss"></div>
                   <div class="or mt-2">OR</div>
                   <div class="col "><hr class="sss"></div>
               </div>
               <div class="row" fxLayout.xs="column"fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign.xs="center" >
               <div class="mx-auto mt-2" >
                <img class="fgi" src=" ../../assets/img/Group 102.png" >
                <img class="fgi" src=" ../../assets/img/Group 104.png" >
              </div>
             </div>
             <div class="mt-3">
              <p> Don't have an account? <a  class=" sing " href="#" > Sing up</a></p>
            </div>
             </form>
           </div>
         </mat-card>
       
    </div>
   </div>