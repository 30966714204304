<div class="">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="center">
        <div class="" fxFlex="50%">
            <img width="100%" height="590" class="mt-3" src="../../../assets/img/g10.png" />
        </div>
        <div class="mr-5" fxFlex="50%">
            
            <form class="mt-1" fxFlex="100%">
                <div class="text-center">
                   <img width="269px" height="180" src="../../assets/img/exploreologo.png">
                   <h2 class="font_size_css mt-4">Admin Login</h2>
                </div>
                <mat-label>Email/User ID</mat-label><br>
                <mat-form-field class="w-100" appearance="outline">
                    <input matInput placeholder="Email Address">
                </mat-form-field>
                  <mat-label>Password</mat-label><br>
                <mat-form-field class="w-100" appearance="outline">
                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <section class="example-section text-white" fxLayout="row" fxLayout.xs="">
                    <div class=" text-white text-center">
                        <mat-checkbox class="example-margin text-white text-dark">Remember me</mat-checkbox>
                    </div>
                    <div class="ml-auto mt-1">
                        <a href="" class="text-decoration-none float-right">Forgot your password?</a>
                    </div>
                </section>
  
                <div class=" mt-3">
                    <button class="w-100" mat-raised-button style="background-color: #1b9ae2; color: white;border-radius: 9px;">Login</button>
                </div>
            </form>
        </div>
    </div>
  </div>
  