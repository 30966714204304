import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket:any;

  constructor() { }
  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT);
  this.socket.emit('my message', 'TESTHello there from Angular.');
  this.socket.on(environment.SOCKET_ENDPOINT, (data: string) => {
    console.log(data);
  });
  }
  
}
