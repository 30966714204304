<div class="chat-messages">
    <div class="chat-messages-show-container">
      <ul class="chat-messages-show-list">
      </ul>
    </div>
    <div class="chat-messages-create-container"> 
        <input #box
        (keyup.enter)="SendMessage(box.value)"
        (blur)="SendMessage(box.value)">
  
      <p>{{value}}</p>
    </div>
 </div>