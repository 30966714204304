import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-profile-post',
  templateUrl: './profile-post.component.html',
  styleUrls: ['./profile-post.component.css']
})
export class ProfilePostComponent implements OnInit {


  
  HightlightCate:any;
  ProfileDetail:any;
  following:any;
  follower:any;
  events:any;
  imageUrl = environment;
  postImageUrl = environment;
  currentUserId = 1;
  profilePost:any;
  constructor(
    private http : HttpClient,) {
      this.profilePost={data:{highlitCate:[]}}
      // events.data
    }


 ngOnInit(): void {
   
  this.http.get(environment.apiUrl+'user/getMyProfile/1/29')
  .subscribe(datas => {
    this.profilePost = datas; 
    console.log("profile Posts  :"+this.profilePost);
    this.following = this.ProfileDetail.data.following.length;
      this.follower = this.ProfileDetail.data.follower.length;
});
  }

}
