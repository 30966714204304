
    <img class="ml-4" width="69%" src="../../assets/img/exploreologo.png">
        <div class="mt-4 ml-5 d-flex">
            <a href="profile/post" class="text-decoration-none">
                <img width="45px" height="45px" class="rounded-circle" src="../../assets/img/pexels-john-stelios.png">
            </a>
            <div class="mt-1">
                <a href="profile/post" class="text-decoration-none">
                <span class="ml-3 text-dark" style="font-family: 'Noto Sans JP', sans-serif;">
                Salim Khan
                </span>
                <p class="ml-3 mt-1 text-muted">Salim Khan</p>
            </a>
            </div>
        </div>
        <div class="mt-5 ml-5 pl-2">
            <a href="save/post" class="d-flex text-decoration-none text-dark">
                <img width="20px" height="26px" src="../../assets/img/ic_turned_in_24px.png">
                <p class="ml-4 pl-2 F_size">Saved</p>
            </a>
        </div>
        <div class="mt-4 ml-5 pl-2">
            <a href="/archieved/post" class="d-flex text-decoration-none text-dark">
                <img width="23px" height="25px" src="../../assets/img/clock (1).png">

                <p class="ml-4 pl-2 F_size">Archieved</p>
            </a>
        </div>
        <div class="mt-4 ml-5 pl-2">
            <a href="chat-message" class="d-flex text-decoration-none text-dark">
                <img width="23px" height="23px" src="../../assets/img/Icon material-chat-bubble.png">
                <p class="ml-4 pl-2 F_size">Message</p>
            </a>
        </div>
        <!-- <div class="mt-4 ml-5 pl-2">
            <a href="event/create" class="d-flex text-decoration-none text-dark">
                <img width="22px" height="24px" src="../../assets/img/Group 493.png">
                <p class="ml-4 pl-2 F_size">Create event</p>
            </a>
        </div> -->
        <div class="col-12 col-lg-12">
            <div class="dropdown mt-4">
                <button type="button" class="btn w-100 dropdown-toggle" data-toggle="dropdown">
                    see more
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Link 1</a>
                    <a class="dropdown-item" href="#">Link 2</a>
                    <a class="dropdown-item" href="#">Link 3</a>
                </div>
            </div>
        </div>
    