import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-home',
  templateUrl: './storypopup.component.html',
  styleUrls: ['./story.component.css']
})
export class StorypopupComponent implements OnInit {
  pValu = 0;
  pValu1 = 0;
  userId = 1;
  storiesData:any;
  postData:any;
  showMore = false;
  message:any;
  responses:any;
  selected = false;
  suggestion:any;
  response:any;
  imageUrl = environment.imageUrl;

  constructor(
    private http : HttpClient,) {}

  // slidess = [{'image': '../../assets/img/glenn-hansen-FMi26r2xrCk-unsplash (1).jpg'},
  // {'image': 'https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg'},
  // {'image': 'https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg'}];
  


  async storyviewData() {
    this.message = "Fetching..";
    console.log("Fetching..");
    this.response = "";
    this.response = await this.http
      .get<any>(environment.apiUrl+'highlight/getstory/'+this.userId)
      .pipe()
      .toPromise();
      console.log("story view get data",this.response)
    this.message = "Fetched";
  }
  addProgressBar(val:any) {
    this.pValu = val;
  }
  ngOnInit(): void {
    this.postData = this.storyviewData();
    setInterval(() => {
      let val = this.pValu1++;
      console.log(val);
      this.addProgressBar(val);
    }, 500);

  }

}
