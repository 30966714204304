<div class="container ">
    <div fxLayout.xs="column" fxLayoutGap="%"fxLayoutAlign.xs="center">
       <mat-card class="example-card col-sm-12 col-md-12 col-12 mx-auto ">
           <img class=" mx-auto imgc" mat-card-image src="../../assets/img/exploreologo.png" >
           <div class="container">
           <form class="mt-4">
               <p class="text-muted">Enter the four digit OTP we sent to<br> +91 9079340786</p>
                    <div class="container mt-4">
                        <div class="d-flex">
                            <div class="col-lg-3">
                                <div class="input_css">
                                    <input class="form-control" maxlength="1"  />
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <input class="form-control " maxlength="1"  />
                            </div>
                            <div class="col-lg-3">
                                <input class="form-control" maxlength="1"  />
                            </div>
                            <div class="col-lg-3">
                                <input class="form-control" maxlength="1"  />
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="ml-3">
                                <h4>00.31</h4>
                            </div>
                            <div class="ml-auto mr-3">
                                <a href="" class="text-decoration-none" style="color: #046FB7;">Resend Code</a>
                            </div>
                        </div>
                    </div>
                    
                 <button class="rounded-0 bbc text-white mt-4" mat-raised-button >Continue</button>
             </form>
           </div>
         </mat-card>
       
    </div>
   </div>