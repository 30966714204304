<!-- {{userPost.value | json}}asdas -->
<app-side-navs></app-side-navs>
<div class="container">
    <div class="profile_css" fxLayout="row" fxLayout.xs="" fxLayoutWrap fxLayoutGap="7%" fxLayoutAlign="center">
        <div fxFlex.xs="35%" fxFlex.lg="20%" class="border_css">
            <app-setting-sidebar></app-setting-sidebar>
        </div>
        <!-- #userPost="ngForm" (ngSubmit)="onSubmit(1)" -->
        <form  class="w-100">
            <div fxFlex.xs="65%" fxFlex.lg="80%" class="mt-4">
                <div class="text-center">
                    <img width="97px" height="97px" class="image_css_mobile" src="../../assets/img/pexels-john-stelios.png" />
                </div>
                <div class="text-center mt-3">
                    <a href="" class="mt-5 text-decoration-none">Change Profile Photo</a>
                </div>                      
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-4">
                    <div fxFlex="25%">
                        <h2 class="mt-2">Name</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" />
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-2">
                    <div fxFlex="25%">
                        <h2 class="">Bio</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" id="bio" [(ngModel)]="profilePost.bio" name="bio"/>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-2">
                    <div fxFlex="25%">
                        <h2 class="">User Name</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" id="username" [(ngModel)]="profilePost.username" name="username"/>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-2">
                    <div fxFlex="25%">
                        <h2 class="">Phone Number</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" id="phone_no" [(ngModel)]="profilePost.phone_no" name="phone_no"/>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-2">
                    <div fxFlex="25%">
                        <h2 class="">Email</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" id="email" [(ngModel)]="profilePost.email" name="email"/>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center" class="mt-2">
                    <div fxFlex="25%">
                        <h2 class="">Date of Birth</h2>
                    </div>
                    <mat-form-field fxFlex="70%" appearance="outline">
                        <input matInput placeholder="" id="dob" [(ngModel)]="profilePost.dob" name="dob"/>
                    </mat-form-field>
                </div>
                <div class="text-center">
                    <button class="btn_color_css" mat-raised-button>SUBMIT</button>
                </div>
            </div>
        </form>
    </div>
</div>
