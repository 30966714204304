<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="center">
  <div fxFlex="25%">
      <app-side-nav></app-side-nav>
  </div>
  <div class="example" fxFlex="75%">
      <div class="container mt-3 profile_left_side">
          <div class="d-flex mx-auto profile_mobile">
              <div class="col-lg-5 col-md-4 col-sm-3 float-right">
                  <button mat-raised-button class="follow_btn_css float-right">{{profilePost.data.follower.length}} followers</button>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-2 text-center">
                  <img width="120px" height="120px" class="rounded-circle profile_mobile_img profile_img2" src="../../assets/img/pexels-john-stelios.png" />
              </div>
              <div class="col-lg-5 col-md-4 col-sm-3 salim_css_btn">
                  <button mat-raised-button class="follow_btn_css">{{profilePost.data.following.length}} following</button>
              </div>
          </div>
      </div>
      <div class="profile_mobile mt-4 text-center">
          <h2 class="fs">{{profilePost.data.username}}</h2>
      </div>
      <div class="text-center">
          <p class="text-muted fs_mobile">{{profilePost.data.bio}}</p>
      </div>
      <div class="text-center mt-3">
          <button mat-raised-button class="b_radiuas1">Edit Profile</button>
          <button mat-raised-button class="ml-3 b_radiuas b_radiuas_m">Contact</button>
      </div>

      <!-- logic && operator -->
      <!-- <div class="text-center" *ngIf="currentUserId != ProfileDetail.data.id">
              <button mat-raised-button class="b_radiuas1">Message</button>
              <button mat-raised-button class="ml-3 b_radiuas b_radiuas_m">Follow</button>
            </div> -->

      <div class="container container_1">
          <div class="d-flex myStory mt-4 container">
              <div class="mr-4" *ngFor="let highlitData of profilePost.data.highlitCate">
                  <img class="story story_status" style="z-index: -1 !important;" src="../../assets/img/pexels-john-stelios.png" />
                  <p class="mt-3 title_size text-center">{{highlitData.title}}</p>
              </div>
          </div>
          <hr />
      </div>
      <mat-tab-group mat-align-tabs="center" class="remove-border-bottom">
          <mat-tab class="col-lg-4 col-sm-4 co-md-12">
              <ng-template mat-tab-label>
                  <img width="15px" height="15px" class="" src="../../assets/img/menu (1).png" />
                  <h4 class="ml-2 mt-3" style="font-size: 12px;">POSTS</h4>
              </ng-template>
              <div class="">
                  <div *ngFor="let event of profilePost.data.event" class="col-lg-4 col-md-4 col-sm-4 col-4 mt-4 float-right">
                      <img width="100%" class="mobile_css" src="https://wallpapercave.com/wp/wp2691049.jpg" />
                      <p>{{event.event_name}}</p>
                  </div>
              </div>
          </mat-tab>

          <mat-tab class="col-lg-4 col-sm-4 co-md-12">
              <ng-template mat-tab-label>
                  <img width="15px" height="15px" src="../../assets/img/Path 31.png" />
                  <h4 class="ml-2 mt-3" style="font-size: 12px;">TAGGED</h4>
              </ng-template>
              <div class="">
                  <div *ngFor="let event of profilePost.data.event" class="col-lg-4 col-md-4 col-sm-4 col-4 mt-4 float-right">
                      <img width="100%" class="mobile_css" src="https://wallpapercave.com/wp/wp2691049.jpg" />
                      <p>{{event.event_name}}</p>
                  </div>
              </div>
          </mat-tab>

          <mat-tab class="col-lg-4 col-sm-4 co-md-12">
              <ng-template mat-tab-label>
                  <img width="15px" height="15px" src="../../assets/img/Group 512.png" />
                  <h4 class="ml-2 mt-3" style="font-size: 12px;">MY EVENTS</h4>
              </ng-template>
              <div class="">
                  <div *ngFor="let event of profilePost.data.event" class="col-lg-4 col-md-4 col-sm-4 col-4 mt-4 float-right">
                      <img width="100%" class="mobile_css" src="https://wallpapercave.com/wp/wp2691049.jpg" />
                      <p>{{event.event_name}}</p>
                  </div>
              </div>
          </mat-tab>
      </mat-tab-group>
  </div>
</div>
<div class="mobile_size_show fixed-bottom">
  <div class="d-flex mt-2">
      <div class="col-sm-2 col-md-3">
          <img width="35px" height="35px" class="mt-1" src="../../assets/img/home (1).png" />
      </div>
      <div class="col-sm-2 col-md-3">
          <img width="35px" height="35px" class="mt-1" src="../../assets/img/calendar.png" />
      </div>
      <div class="col-sm-4 col-md-3">
          <img width="56px" height="56px" class="photo_icon_css mt-1" src="../../assets/img/group_299.png" />
      </div>
      <div class="col-sm-2 col-md-3">
          <img width="35px" height="35px" class="mt-1" src="../../assets/img/notification.png" />
      </div>
      <div class="col-sm-2 col-md-3">
          <img width="35px" height="35px" type="button" class="mr-1 mt-1" src="../../assets/img/crash-helmet.png" />
      </div>
  </div>
</div>

<!-- <div class="container" [class.show]="show">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      
    </div>
    <button class="text-center" (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button> -->
